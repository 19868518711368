<owl-carousel-o [options]="productosPromoNuevos2">  
    <ng-template carouselSlide *ngFor="let p of productos; let i=index">
      <div class="row divSelect">
        
           <div class="col  m-4">
            <div class="card  box p-2">
                <div class="card-body " >
                    <div class="row">
                        <div class="col-5 ">
                            <div class="badge">Promo</div>
                            <img class=" p-1" src="https://drive.google.com/uc?export=view&id={{p.imagenPrincipal}}" alt="" style="width: 100%; height: auto; object-fit: cover;  border-radius: 7px; ">
                        </div>
                        <div class="col-7" >
                            <div class="product-text">
                              <h2>{{p.marca_nombre}}</h2>
                              <p>{{p.pro_nom}} </p>
                            </div>
                            <p class="text-size-11  mb-1 text-dorado divSelect">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                          </p>

                          <div class="card-body text-minuscula pt-1 ">
                            <p class="text-size-14 text-center sin-espacios"
                                *ngIf="configurationVariables.mostrar_precio">
                                <span class="text-secondary mr-2 text-subrayado-gray"
                                    *ngIf="p.precioOferta > 0">${{p.precioOferta | number: '1.2'}} </span>
                                <span style="font-family:'Helvetica' ; font-weight: bold;font-size: 1.8em; "> $ {{p.precioReal | number: '1.2'}} </span>
                            </p>
                            <p class="fw-bold  font-family='Helvetica'" style="font-size: 12px; color:#2478c1; text-align: center;">
                              Disponible en stock {{p.stockactual}}
                            </p>
                            
                            <p class="text-size-11 sin-espacios mt-1 pt-1 pb-1 bg-light rounded border border-warning text-center"
                                *ngIf="p.combo_venta_volumen">
                                <span *ngFor="let pric of p.precios">
                                    <strong *ngIf="pric.combo">
                                        {{pric.combo}}
                                        <br>
                                    </strong>
                                </span>
                            </p>

                        </div>

                        <div class=" button-group btn-group-sm divSelect">
                          <button class="btn  rounded-circle m-1"  title="Agregar a carrito">
                            <i class="bi bi-cart4"></i>
                          </button>
                          <button  class="btn btn-warning rounded-circle m-1"  title="Ver detalle producto">
                            <i class="bi bi-eye-fill"></i>
                          </button>
                          <button class="btn btn-danger rounded-circle m-1" title="Compartir">
                            <i class="bi bi-share-fill"></i>
                          </button>
                        
                        </div>

                            
                        </div>
                    </div>
             
               
                 
                </div>
              </div>
           </div>


      </div>


</ng-template>

</owl-carousel-o>