import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { GapiSession } from '../infrastructure/sessions/gapi.session'
import { AppRepository } from 'src/infrastructure/repositories/app.repository';
import { FileRepository } from 'src/infrastructure/repositories/file.repository';
import { UserRepository } from 'src/infrastructure/repositories/user.repository';
import { AppContext } from 'src/infrastructure/app.context';
import { BreadCrumbSession } from 'src/infrastructure/sessions/breadcrumb.session';
import { FileSession } from 'src/infrastructure/sessions/file.session';
import { UserSession } from 'src/infrastructure/sessions/user.session';
import { AppSession } from 'src/infrastructure/sessions/app.session';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule } from 'ngx-loading';
// import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
// import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { NgxPaginationModule } from 'ngx-pagination';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxPrintModule } from 'ngx-print';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { QRCodeModule } from 'angularx-qrcode';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { MenuComponent } from './shared/menu/menu.component';
import { FooterComponent } from './shared/footer/footer.component';
import { CarritoComponent } from './pages/carrito/carrito.component';
import { SubmenuComponent } from './shared/submenu/submenu.component';
import { CatalogoComponent } from './shared/catalogo/catalogo.component';
import { ProductsComponent } from './pages/products/products.component';
import { LoginAdminComponent } from './pages/login/login-admin/login-admin.component';
import { LoginUserComponent } from './pages/login/login-user/login-user.component';
import { ScrollToTopComponent } from './shared/scroll-to-top/scroll-to-top.component';
import { AdminComponent } from './pages/profile/admin/admin.component';
import { UserComponent } from './pages/profile/user/user.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PoliticsAndTermsComponent } from './pages/politics-and-terms/politics-and-terms.component';
import { MenuResponsivoComponent } from './shared/menu-responsivo/menu-responsivo.component';
import { DetailProductComponent } from './shared/detail-product/detail-product.component';
import { ComponentesModule } from './componentes/componentes.module';
import { InicioComponent } from './pages/inicio/inicio.component';
import { SafePipe } from './pipes/safe.pipe';

import { VaucherComponent } from './pages/vaucher/vaucher.component';

export function initGapi(gapiSession: GapiSession) {
  console.log("el init del app");
  return () => gapiSession.initClient();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    FooterComponent,
    CarritoComponent,
    SubmenuComponent,
    CatalogoComponent,
    ProductsComponent,
    LoginAdminComponent,
    LoginUserComponent,
    ScrollToTopComponent,
    AdminComponent,
    UserComponent,
    AboutUsComponent,
    PoliticsAndTermsComponent,
    MenuResponsivoComponent,
    DetailProductComponent,
    InicioComponent,
    SafePipe,
    // SafePipe
    
    VaucherComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    NgbModalModule,
    AppRoutingModule,
    HttpClientModule,
    // SocialLoginModule,
    Ng2TelInputModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgbModule,
    CarouselModule,
    NgxPayPalModule,
    NgxPrintModule,
    YouTubePlayerModule,
    ComponentesModule,
    ReactiveFormsModule,
    QRCodeModule, 

  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initGapi, deps: [GapiSession], multi: true },
    GapiSession,
    AppRepository,
    FileRepository,
    UserRepository,
    AppContext,
    BreadCrumbSession,
    FileSession,
    UserSession,
    AppSession,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
