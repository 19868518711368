import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-card-component',
  templateUrl: './card-component.component.html',
  styleUrls: ['./card-component.component.scss']
})
export class CardComponentComponent implements OnInit {
  @Input('productos') productos: any;
  @Input('configurationVariables') configurationVariables: any;
  @Input('configuracion') configuracion: any;
  productosPromoNuevos2: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fas fa-angle-double-left" style="color: red;"></i>',
      '<i class="fas fa-angle-double-right text-ligth"></i>'],
    responsive: {
      0: {
        items: 0
      },
      300: {
        items: 1
      },
      600: {
        items: 2
      },
      700: {
        items: 4
      },
      1000: {
        items: 4
      },
    },
    nav: true
  }
  productos1:any;
  constructor() { }

  ngOnInit(): void {


    console.log('lleg[o',this.productos);
    // console.log('config',this.configurationVariables);
    console.log('config',this.configuracion);
   

    
  }



}
