import {  Component,  OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppContext } from '../../../infrastructure/app.context';
import { Meta } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Params } from '@angular/router';

import { ServicesService } from '../../shared/services/services.service';
import { LoginUserComponent } from '../login/login-user/login-user.component';
import { DetailProductComponent } from '../../shared/detail-product/detail-product.component';



declare var $: any; // ADD THIS
import * as $ from 'jquery';
let apiLoaded = false;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  
  public empresa: any;
  public loadingAll = false;
  public informacion: any;
  public imagenBanner = [];
  public configuracion: any = {};
  public login: any = {};
  public closeResult: string;
  public datosSugerencia = {
    nombres: '',
    correo: '',
    telefono: '',
    mensaje: '',
  }
  // public tendencia = {
  //   promociones: true,
  //   nuevos: false,
  //   grupos: false
  // }
  public productos = [];
  public productSelected: any;
  public promocionesWeb = {
    promociones: [],
    titulo: ''
  }
  public promotionSelected: any = [];
  public productsSold = [];
  public guarnition: any = [];
  public groups: any = [];
  public groupSelected: any = [];


  // Por defecto el precio pA
  // public tipoPrecio = 'pA';
  public configurationVariables = {
    tipo_precio: 'pA', // No se esta usando
    mostrar_precio: 1,
    show_attributes_prod: false
  }

  // Banner Horizonal productos de promociones, nuevos, grupos
  productosPromoNuevos: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fas fa-angle-double-left" style="color: red;"></i>',
      '<i class="fas fa-angle-double-right text-ligth"></i>'],
    responsive: {
      0: {
        items: 0
      },
      300: {
        items: 1
      },
      600: {
        items: 2
      },
      700: {
        items: 4
      },
      1000: {
        items: 4
      },
    },
    nav: true
  }
  productosPromoNuevos2: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fas fa-angle-double-left" style="color: red;"></i>',
      '<i class="fas fa-angle-double-right text-ligth"></i>'],
    responsive: {
      0: {
        items: 0
      },
      300: {
        items: 1
      },
      600: {
        items: 2
      },
      700: {
        items: 2
      },
      1000: {
        items: 3
      },
      1300: {
        items: 3
      },
      1400: {
        items: 4
      }
    },
    // responsive: {
    //   0: {
    //     items: 0
    //   },
    //   300: {
    //     items: 1
    //   },
    //   600: {
    //     items: 2
    //   },
    //   700: {
    //     items: 2
    //   },
    //   1000: {
    //     items: 4
    //   },
    // },
    nav: true
  }

  // Banner Horizontal promociones
  promocionWeb: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fas fa-angle-double-left" style="color: red;"></i>',
      '<i class="fas fa-angle-double-right text-ligth"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    nav: true
  }

  constructor(
    private toaster: ToastrService,
    private webService: ServicesService,
    public router: Router,
    private appContext: AppContext,
    public meta: Meta,
    private modalCtrl: NgbModal,
    private localStorage: LocalStorage,
    private activateRoute: ActivatedRoute,

  ) {

    // this.webService.uploadScripts(['carrusel']);
    // Para que no salga error
    // console.log(this.appContext.Session.Gapi.isSignedIn);
    if (!this.appContext.Session.Gapi.isSignedIn) {
      // this.signInGoogle();
      console.log("Google No");
    } else {
      console.log("Google Yes");
    }
  }

  async ngOnInit() {
    // Cargar atributos para el video de youtube
    const tag = await document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    await document.body.appendChild(tag);
    apiLoaded = true;
    // LLamar a funciones para cargar datos
    await this.getConfiguracion();
    await this.getPromocionesWeb();
    await this.getBanner();
    await this.getProductPopularSold();
    // Redirigir a la pagina por defecto definida por el administrador
    await this.activateRoute.params.subscribe(async (params: Params) => {
      if (!params.value) {
        this.router.navigateByUrl(this.configuracion.ruta_inicio_defecto);
      }
    });

  }



  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  async getConfiguracion() {
    this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        this.configuracion = data[0];
        await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then((resauth: any) => {
          this.login = resauth;
          if (resauth.rta == true) {
            this.configurationVariables.mostrar_precio = 1;
          } else {
            this.configurationVariables.mostrar_precio = this.configuracion.mostrar_precio;
          }
        });
        this.configurationVariables.show_attributes_prod = this.webService.showAtttibutesProducts(this.configuracion);
        // Obtener datos de la empresa
        await this.getDataEmpresa();
        // Asignar que productos de tendencia a mostrar
        switch (this.configuracion.productos_tendencia) {
          case 1:
            await this.tendenciasSelect('nuevos');
            break;
          case 2:
            await this.tendenciasSelect('promociones');
            break;
          case 3:
            await this.tendenciasSelect('grupos');
            break;
          default:
            console.log("Default switch");
        }
        // Asignar titulo de la promocion
        this.promocionesWeb.titulo = this.configuracion.tituloPromocion;
        this.configurationVariables.show_attributes_prod = this.webService.showAtttibutesProducts(this.configuracion);

        //btn Comprar Card Mas Populares

        let btnCMP = document.getElementById('btnCardComprarMP');
        btnCMP.style.background = this.configuracion.colorPrincipal;
        btnCMP.style.color = this.configuracion.colorLetra;

        //BTN CARRITO
        for (let i = 0; i < this.productsSold.length; i++) {
          let btnC = document.getElementById('btnCardCarrito'+i);
          
          btnC.style.background = this.configuracion.colorPrincipal;
          btnC.style.color = this.configuracion.colorLetra;   
        }
       

        // Boton sugerencia
        if (document.getElementById('btn-sugerencia')) {
          let bsug = document.getElementById('btn-sugerencia');
          bsug.style.background = this.configuracion.colorPrincipal;
          bsug.style.color = this.configuracion.colorLetra;
        }
        if (document.getElementById('str-mas-vendido')) {
          let strmv = document.getElementById('str-mas-vendido');
          strmv.style.color = this.configuracion.colorPrincipal;
        }
        if (document.getElementById('div-trend')) {
          let dt = document.getElementById('div-trend');
          dt.style.borderLeft = '8px double' + this.configuracion.colorPrincipal;
          // dt.style.borderRight = 'medium solid' + this.configuracion.colorPrincipal;
        }
        if (this.promocionesWeb.promociones.length > 0) {
          if (document.getElementById('div-promo')) {
            let dp = document.getElementById('div-promo');
            dp.style.borderBottom = 'thin solid ' + this.configuracion.colorPrincipal;
          }
          
        }
        this.meta.updateTag({ property: "og:url", content: this.configuracion.dominioPagina });
        this.meta.updateTag({ property: "og:image", content: "https://drive.google.com/open?id=15Rqx2hoxpiQoR7bN5eioTO2WIjJaFv8r" });
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async getDataEmpresa() {
    await this.webService.getEmpresaService().then(async (resEmpr: any) => {
      this.empresa = resEmpr.data[0];
    });
    // await this.tendenciasSelect('nuevos');
  }

  async getBanner() {
    await this.webService.getImagesBanner().then((data: []) => {
      this.imagenBanner = data;
    });
  }

  async getPromocionesWeb() {
    await this.webService.getPromocionesWeb().then((resProm: any) => {
      if (resProm) {
        for (let p of resProm) {
          p.target = '#' + p.id_promocion;
          p.view = false;

          if (resProm.length > 2) {
            // Titulo
            p.titulo = this.webService.setDescriptionTitle(p.nombrePromocion, 'title-promotion');
            // Descripcion
            if (p.descripcion) {
              if (p.descripcion.length > 70) {
                p.preliminar = p.descripcion.slice(0, 65) + ' ...';
                p.vermas = true;
              } else {
                p.preliminar = p.descripcion;
                p.vermas = false;
              }
            } else {
              p.preliminar = '';
              p.vermas = false;
            }
          } else {
            // Titulo
            p.titulo = p.nombrePromocion;
            // Descripcion
            if (p.descripcion) {
              p.preliminar = p.descripcion;
              p.vermas = false;
            } else {
              p.preliminar = '';
              p.vermas = false;
            }
          }
        }
      }
      this.promocionesWeb.promociones = resProm;
              //BTN CARD

      
    });
    // console.log("this.promocionesWeb", this.promocionesWeb);
  }

  async seeMoreDescription(promocion) {
    promocion.view = !promocion.view;
  }

  async sendSugerencia(enviarSugerenciaModal) {
    this.datosSugerencia = {
      nombres: '',
      correo: '',
      telefono: '',
      mensaje: '',
    }
    await this.modalCtrl.open(enviarSugerenciaModal, { ariaLabelledBy: 'modal-basic-title', size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      let data = result;
      if (data.nombres && data.correo && data.telefono && data.mensaje) {
        if (this.webService.validateEmail(data.correo) == true) {
          this.loadingAll = true;
          await this.webService.createBodyMailSupport(this.configuracion, data).then(async (ressugerencia: any) => {
            console.log(ressugerencia);

            await this.webService.sendMailService(ressugerencia).then((resmail: any) => {
              console.log(resmail);
              if (resmail.rta == true) {
                this.toaster.success('Sugerencia enviada con éxito', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              } else {
                this.toaster.error('No se ha podido enviar la sugerencia, Intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            });
          });
          this.loadingAll = false;
        } else {
          this.toaster.error('Correo electónico no válido', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      } else {
        this.toaster.error('Campos vacios, Ingrese la información requerida', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // console.log("Cerro", reason);
    });
  }

  // valor = tendencia
  // valor = nuevo
  // valor = grupos
  async tendenciasSelect(valor) {
    this.productos = [];
    this.groups = [];
    this.groupSelected = [];
    if (valor == 'promociones') {

        //Color SubMenu Productos
        


      // Promociones
      if (document.getElementById('promociones')) {
        let promo = document.getElementById('promociones');
        promo.style.background = this.configuracion.colorPrincipal;
        promo.style.color = this.configuracion.colorLetra;
      }
      //title
      let nuevo = document.getElementById('promociones1');
      // nuevo.style.background = 'white';
      nuevo.style.color = this.configuracion.colorPrincipal;
      // Nuevos
      if (document.getElementById('nuevos')) {
        let nuevo = document.getElementById('nuevos');
        nuevo.style.background = 'white';
        nuevo.style.color = this.configuracion.colorPrincipal;
              //title
      let nuevo1 = document.getElementById('promociones1');
      // nuevo.style.background = 'white';
      nuevo1.style.color = this.configuracion.colorPrincipal;
      }
      // Grupos
      if (document.getElementById('grupos')) {
        let grupos = document.getElementById('grupos');
        grupos.style.background = 'white';
        grupos.style.color = this.configuracion.colorPrincipal;
              //title
      let nuevo = document.getElementById('promociones1');
      // nuevo.style.background = 'white';
      nuevo.style.color = this.configuracion.colorPrincipal;
      }
      // Obtener productos de promocion billing
      await this.webService.getTemporaryPromotions().then(async (respromotion: any) => {
        if (respromotion.rta == true) {
          this.productos = respromotion.data;

          // console.log("TIPO 1", this.productos);
          
    
        
          
        } else {
          await this.getProductospromocion().then(async (resProdProm: any) => {
            // console.log("promociones", resProdProm);
            this.webService.saveTemporaryPromotions(true, resProdProm);
            if (resProdProm.length > 0) {
              this.productos = resProdProm;
              // console.log( 'PROMOS',this.productos);
                      //btn show, car, shared
                      // for (let i = 0; i < this.productos.length; i++) {
                      //   let nuevo = document.getElementById('btnAddCar');
                      //   console.log(nuevo);
                        
                      //   nuevo.style.background = 'white';
                      //   nuevo.style.color = '#4DBDC0';
                        
                      // }

        
            } else {
              this.toaster.warning('Actualmente la Tienda no posee productos de promoción.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        }
      });
    }

    if (valor == 'nuevos') {
      // Promociones
      if (document.getElementById('promociones')) {
        let promo = document.getElementById('promociones');
        promo.style.background = 'white';
        promo.style.color = this.configuracion.colorPrincipal;
              //title
      let nuevo = document.getElementById('promociones1');
      // nuevo.style.background = 'white';
      nuevo.style.color = this.configuracion.colorPrincipal;
      }
      // Nuevos
      if (document.getElementById('nuevos')) {
        let nuevo = document.getElementById('nuevos');
        nuevo.style.background = this.configuracion.colorPrincipal;
        nuevo.style.color = this.configuracion.colorLetra;
              //title
      let nuevo1 = document.getElementById('promociones1');
      // nuevo.style.background = 'white';
      nuevo1.style.color = this.configuracion.colorPrincipal;
      }
      // Grupos
      if (document.getElementById('grupos')) {
        let grupos = document.getElementById('grupos');
        grupos.style.background = 'white';
        grupos.style.color = this.configuracion.colorPrincipal;
              //title
      let nuevo = document.getElementById('promociones1');
      // nuevo.style.background = 'white';
      nuevo.style.color = this.configuracion.colorPrincipal;
      }
      // Obtener productos de promocion billing
      await this.webService.getTemporaryProductosNew().then(async (resnew: any) => {
        if (resnew.rta == true) {
          this.productos = resnew.data;
        } else {
          await this.getProductosNew().then(async (resProdNew: any) => {
            // console.log("nuevos", resProdNew);
            await this.webService.saveTemporaryProductosNew(true, resProdNew);
            if (resProdNew.length > 0) {
              this.productos = resProdNew;
              // console.log("productos", this.productos);
            } else {
              this.toaster.warning('Actualmente la Tienda no posee productos nuevos.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        }
      });
    }

    if (valor == 'grupos') {
      // Promociones
      if (document.getElementById('promociones')) {
        let promo = document.getElementById('promociones');
        promo.style.background = 'white';
        promo.style.color = this.configuracion.colorPrincipal;
      }
      // Grupos
      if (document.getElementById('nuevos')) {
        let nuevo = document.getElementById('nuevos');
        nuevo.style.background = 'white';
        nuevo.style.color = this.configuracion.colorPrincipal;
      }
      // Nuevos
      if (document.getElementById('grupos')) {
        let grupos = document.getElementById('grupos');
        grupos.style.background = this.configuracion.colorPrincipal;
        grupos.style.color = this.configuracion.colorLetra;
      }
      // Obtener grupos
      await this.webService.getTemporaryCatalogue().then(async (resgrupos: any) => {
        if (resgrupos) {
          // console.log("Temporarl", resgrupos);
          this.groups = resgrupos;
        } else {
          await this.webService.getGruposService(this.empresa.url_billing, 'filter').then(async (rescategorias: any) => {
            if (!rescategorias.error) {
              if (rescategorias.rta == true) {
                await this.getSubgrupos(rescategorias.data, this.empresa.url_billing).then(async (ressubgrup: any) => {
                  this.groups = ressubgrup;
                  // console.log("grupos", this.groups);
                });
              }
            }
          });
        }
      });
    }
  }

  async getSubgrupos(grupos, url_billing) {
    this.loadingAll = true;
    for (let g of grupos) {
      g.nombre = await this.webService.convertStringTypeSentence(g.nombre);
      await this.webService.getSubgruposService(url_billing, g.idgrupo).then(async (resSubgrup: any) => {
        g.subgrupos = resSubgrup.data;
      });
    }
    this.loadingAll = false;
    return grupos;
  }

  // tipoWeb = 1 => Tienda normal 
  // tipoWeb = 2 => Tienda por tallas 
  // tipoWeb = 3 => Tienda con 2 BD   ** Supendido por el momento **
  // tipoWeb = 4 => Tienda Internet
  async getProductospromocion() {
    let products;
    this.loadingAll = true;
    if (this.configuracion.tipo_web == 1) {
      // console.log("Tienda normal");
      await this.webService.getProductosPromocionService(this.empresa.url_billing, this.configuracion).then(async (resProdProm: any) => {
        if (resProdProm.rta == true) {
          await this.webService.obtainAndCalculatePriceProduct(resProdProm.data, this.configuracion, this.login).then(async (resPrice) => {
            products = resPrice;
          });
        } else {
          products = [];
          // this.toaster.warning('Actualmente, la tienda no posee productos de promoción.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    }
    if (this.configuracion.tipo_web == 2) {
      // console.log("Tienda por tallas");
      await this.webService.getProductosPromocionService(this.empresa.url_billing, this.configuracion).then(async (resProdProm: any) => {
        if (resProdProm.rta == true) {
          await this.webService.obtainAndCalculatePriceProduct(resProdProm.data, this.configuracion, this.login).then(async (resPrice) => {
            await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
              products = resTalla;
            });
          });
        } else {
          products = [];
        }
      });
    }

    if (this.configuracion.tipo_web == 3) {
      console.log("Tienda con 2 BD, codigo se encuentra en proceso");
    }

    if (this.configuracion.tipo_web == 4) {
      console.log("Tienda Internet");
    }

    // Cuando hay 3 resultados, dejar todo el nombre
    if (products.length <= 3) {
      for (let p of products) {
        p.nombre_producto = p.pro_nom;
      }
    }

    // console.log("Productos Promocion", products);
    this.loadingAll = false;
    return products;
  }

  // tipoWeb = 1 => Tienda normal 
  // tipoWeb = 2 => Tienda por tallas 
  // tipoWeb = 3 => Tienda con 2 BD   ** Supendido por el momento **
  // tipoWeb = 4 => Tienda Internet
  async getProductosNew() {
    let products;
    this.loadingAll = true;

    if (this.configuracion.tipo_web == 1) {
      // console.log("Tienda normal");
      await this.webService.getProductosNewService(this.empresa.url_billing, this.configuracion, 0).then(async (resProdProm: any) => {
        if (resProdProm.rta == true) {
          await this.webService.obtainAndCalculatePriceProduct(resProdProm.data, this.configuracion, this.login).then(async (resPrice) => {
            products = resPrice;
          });
        } else {
          products = []
        }
      });
    }

    if (this.configuracion.tipo_web == 2) {
      // console.log("Tienda por tallas", this.configuracion.tipo_web);
      // let prod;
      await this.webService.getProductosNewService(this.empresa.url_billing, this.configuracion, 40).then(async (resProdProm: any) => {
        if (resProdProm.rta == true) {
          // prod = await this.webService.deleteProductsDuplicated(resProdProm.data, 'tagDeGrupo');
          await this.webService.obtainAndCalculatePriceProduct(resProdProm.data, this.configuracion, this.login).then(async (resPrice) => {
            await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
              products = resTalla;
            });
          });
        }
      });
    }

    if (this.configuracion.tipo_web == 3) {
      console.log("Tienda con 2 BD, codigo se encuentra en proceso");
      // let data1;
      // let data2;
      // await this.webService.getProductosNewService(this.empresa.url_billing, this.configuracion, 5).then((resProdProm: any) => {
      //   data1 = resProdProm.data;
      // });

      // await this.webService.getProductosNewService(this.empresa.url_billing_2, this.configuracion, 5).then((resProdProm2: any) => {
      //   data2 = resProdProm2.data;
      // });

      // await this.webService.unifyProductosDosUrls(data1, data2).then(async (resProd) => {
      //   let aux = 4;
      //   let d1;
      //   let d2;
      //   if (resProd.length < 10) {
      //     console.log("entro al if");
      //     while (resProd.length < 10) {
      //       await this.webService.getProductosNewService(this.empresa.url_billing, this.configuracion, aux).then(async (resProdProm: any) => {
      //         d1 = resProdProm.data;
      //       });

      //       await this.webService.getProductosNewService(this.empresa.url_billing_2, this.configuracion, aux).then(async (resProdProm2: any) => {
      //         d2 = resProdProm2.data;
      //       });

      //       await this.webService.unifyProductosDosUrls(d1, d2).then(async (resProd) => {
      //         await this.webService.obtainAndCalculatePriceProduct(resProd, this.configuracion.porcentajePrecioOferta).then(async (resPrice) => {
      //           products = resPrice;
      //         });
      //       });
      //       aux = aux + 1;
      //     }
      //   } else {
      //     console.log("entro al else");
      //     await this.webService.obtainAndCalculatePriceProduct(resProd, this.configuracion.porcentajePrecioOferta).then(async (resPrice) => {
      //       products = resPrice;
      //     });
      //   }
      // });
    }

    if (this.configuracion.tipo_web == 4) {
      console.log("Tienda Internet");
    }

    // this.webService.orderObjectsAsc(products).then((resprod) => {
    //   // console.log("order", resprod);
    // });

    // Cuando hay 3 resultados, dejar todo el nombre
    if (products.length <= 3) {
      for (let p of products) {
        p.nombre_producto = p.pro_nom;
      }
    }

    this.loadingAll = false;

    return products;

  }

  async getProductPopularSold() {
    let url_billing;
    await this.webService.getUrlEmpresa().then(async (url) => {
      url_billing = url;
    });
    await this.webService.getTemporaryProductsPopular().then(async (restemp: any) => {
      if (restemp.rta == true) {
        this.productsSold = restemp.data;
      } else {
        await this.webService.getProductPopularSoldService(url_billing, this.configuracion).then(async (respopular: any) => {
          if (!respopular.error) {
            if (respopular.rta == true) {

              if (this.configuracion.tipo_web == 1) {
                await this.webService.obtainAndCalculatePriceProduct(respopular.data, this.configuracion, this.login).then(async (resPrice) => {
                  this.productsSold = resPrice;
                });
              }

              if (this.configuracion.tipo_web == 2) {
                // let prod;
                // prod = await this.webService.deleteProductsDuplicated(respopular.data, 'tagDeGrupo');
                await this.webService.obtainAndCalculatePriceProduct(respopular.data, this.configuracion, this.login).then(async (resPrice) => {
                  await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                    this.productsSold = resTalla;
                  });

                  // await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                  //   await this.webService.deleteProductsDuplicated(resTalla, 'tagDeGrupo').then((resProd) => {
                  //     this.productsSold = resProd;
                  //   });
                  // });
                });
              }
              await this.webService.saveTemporaryProductsPopular(true, this.productsSold);
            } else {
              await this.webService.saveTemporaryProductsPopular(true, respopular.data);
              // this.toaster.warning('No se ha encontrado productos mas vendidos', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          } else {
            this.toaster.error('Error al conectar con el servidor', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      }
    });
    // console.log("mas vendidos",  this.productsSold);
  }

  async modalViewDetailProduct(product) {
    // Informacion envida al modal
    let send = {
      product: product,
      configuracion: this.configuracion,
      show_price: this.configurationVariables.mostrar_precio,
      show_attributes_product: this.configurationVariables.show_attributes_prod,
      login: this.login,
      productsSold: this.productsSold
    }
    this.webService.setProductSelectedDetail(send);
    this.modalCtrl.open(DetailProductComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'xl' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      console.log("Close", result);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log("Dismiss", reason);
      this.webService.setProductSelectedDetail({});
    });
  }

  async loginClient() {
    this.modalCtrl.open(LoginUserComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
  }

  async addProductShoppingCart(product, client) {

    console.log("PROD", product);
    
    if (product.precioReal > 0) {
      this.loadingAll = true;
      await this.webService.addProductCart(product, client).then(async (res: any) => {
        if (res.rta == true) {
          this.toaster.success(res.mensaje, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.warning(res.mensaje, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
      this.loadingAll = false;
    } else {
      this.toaster.warning('No se puede agregar el producto, precio no válido', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async sharedFacebook(product) {
    let url = this.configuracion.dominioPagina + '/product/selectProduct/' + product.id_producto + '/' + 0;
    let shared = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
    window.open(shared, 'sharer', '_blank');
  }

  async sharedFacebookGroup(group) {
    let url = this.configuracion.dominioPagina + '/product/selectCatalogue/' + group.idgrupo + '/' + 0;
    let shared = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
    window.open(shared, 'sharer', '_blank');
  }

  async selectedPromotionWeb(promo) {
    if (promo.id_producto || promo.id_producto > 0) {
      this.router.navigateByUrl('/product/selectProduct/' + promo.id_producto + '/' + 0);
    } else {
      this.webService.goProductsCatalogue();
    }
  }

  async modalViewDetailPromotion(viewDetailPromotionModal, promotion) {
    this.promotionSelected = promotion;
    this.modalCtrl.open(viewDetailPromotionModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      this.selectedPromotionWeb(result);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // console.log(reason);
    });
    let bgp = document.getElementById('btn-go-promotion');
    bgp.style.background = this.configuracion.colorPrincipal;
    bgp.style.color = this.configuracion.colorLetra;
  }

  public setMethodAddCart(product) {

    console.log(product ,'EL QUE LLEGA');
    
    if (product.talla || product.guarnicion == true) {
      this.modalViewDetailProduct(product);
    } else {
      this.verifyLoginClient(product, 'direct');
    }
  }

  async verifyLoginClient(product, type) {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
      if (login.rta == true) {
        if (type == 'direct') {
          product.quantity = 1;
          // if (product.tallas) {
          //   product.talla = product.tallas[0].talla;
          // }
        }
        await this.addProductShoppingCart(product, login.data);
      } else {
        await this.loginClient();
      }
    });
  }

  // Visualizar el modal con los subgrupos
  async modalShowSubgrupos(showSubgrupos, group) {
    this.groupSelected = [];
    if (group.subgrupos.viewSubgrupo) {
      this.groupSelected = group;
      this.modalCtrl.open(showSubgrupos, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' }).result.then(async (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.goProductsCatalogue('subgroup', group, result);
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      this.goProductsCatalogue('group', group, {});
    }
  }

  async goProductsCatalogue(type, group, subgroup) {
    let url = ''
    if (type == 'group') {
      url = 'product/selectCatalogue/' + group.idgrupo + '/0';
    }
    if (type == 'subgroup') {
      url = 'product/selectCatalogue/' + group.idgrupo + '/' + subgroup.id_sub;
    }
    this.router.navigateByUrl(url);
  }


}
