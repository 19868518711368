import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentesRoutingModule } from './componentes-routing.module';
import { CardComponentComponent } from './card-component/card-component.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { WhatsAppComponent } from './whats-app/whats-app.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [CardComponentComponent, WhatsAppComponent],
  imports: [
    CommonModule,
    ComponentesRoutingModule,
    CarouselModule,
    FormsModule
  ],
  exports:[
    CardComponentComponent,
    WhatsAppComponent
    
  ]
})
export class ComponentesModule { }
