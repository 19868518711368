<app-submenu></app-submenu>

<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>

<!-- Categoria, Banner, Sugerencias -->
<section class="container-fluid">
    <div class="row pt-2 pb-2 pl-3 pr-3 ">
        <!-- Catalogo -->
        <div class="col-lg-3 order-lg-1 order-2 text-center mt-2" id="catalogo-home">
            <app-catalogo></app-catalogo>
        </div>

        <!-- Menu y Banner -->
        <div class="col-lg-6 order-lg-2 order-1 pt-2">
            <!-- <app-menu></app-menu> -->
            <div class="text-center w-100" >
                <ngb-carousel style="   box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.3);max-height: auto; border-radius: 12px;
                ">
                    <ng-template ngbSlide *ngFor="let i of imagenBanner" >
                        <img class="auto w-100" style="   box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.3);max-height: auto; border-radius: 12px;
                        " 
                            src="https://drive.google.com/uc?export=view&id={{i.url}}" id="imgBanner"
                            alt="Random first slide">
                        <div class="carousel-caption"></div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>

        <!-- Sugerencias y promociones -->
        <div class="col-lg-3 order-lg-3 order-4 text-center mt-2">
            <div class="row divSelect">
                <div class="col-lg-10 col-md-8 text-minuscula-texto d-grid gap-2 pt-2 pb-1">
                    <button class="btn btn-sm rounded-pill fw-bold " id="btnCardComprarMP">
                        MÁS POPULARES
                    </button>
                </div>
                
                <div class="col-lg-10" >
                <main role="main" >
                    <div class="product" style="border-radius: 12px;">
                        <div *ngIf="productsSold.length > 0">
                            <ngb-carousel [interval]="10000">
                                <ng-template ngbSlide *ngFor="let p of productsSold; let i = index">
                      <figure style="border-radius: 12px;">
                        <img src="https://drive.google.com/uc?export=view&id={{p.imagenPrincipal}}" alt="Product Image" class="product-image">
                          </figure>
                  
                        <div class="product-description" style="border-radius: 12px;">
                  
                          <div class="info">
                            {{p.nombre_producto}}
           
                          </div>
                  
                          <div class="price">
                            <!-- <p class=""> -->
                                <!-- <span  *ngIf="p.precioOferta > 0"  >
                                    {{p.precioOferta | number: '1.2'}}</span> -->
                                    {{p.precioReal}}
                               <!-- <span>  {{p.precioReal}}</span> -->
                            <!-- </p> -->
                          </div>
                          <div class="pb-2">
                            <button class="btn rounded-pill" id="btnCardCarrito{{i}}" (click)="setMethodAddCart(p)">
                                <i class="bi bi-cart4"></i>
                            </button>
                          </div>
                        </div>
                        <!-- <div class="carousel-caption"></div> -->
                    </ng-template>
                </ngb-carousel>

            </div>
            <div class="text-center" *ngIf="productsSold.length == 0">
                    
                <img class="w-100"
                    src="https://drive.google.com/uc?export=view&id=1_bZD-9CEmvOAdxd08WjfuKWIuhnIxc-2"
                    alt="">
            </div>
                        </div>
                      </main>
                    </div>


            </div>

        </div>
        
    </div>

    <hr>

</section>

<!-- Productos de Promocion, Nuevos, Grupos del billing -->
<section class="container-fluid"  id="menuSectionLG">
    <h1 style="padding-top: 2em;" id="promociones1" style="font-size: 2rem;" >NUESTROS PRODUCTOS </h1>
    <nav class="navbar navbar-expand-lg pb-3    " style="background-color: transparent;">
        <div class="container-fluid">
          <!-- <a class="navbar-brand" href="#">Navbar</a> -->
          <button class="navbar-toggler"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <!-- <span class="navbar-toggler-icon" id="btnOpMenu" ></span> -->
            <i class="fas fa-bars"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 m-auto">
              <li class="nav-item " style="padding: 0.5em ;"  >
                <a class="nav-link btn-sm rounded-pill " style="padding-left: 1em ; padding-right: 1em;" aria-current="page" type="button"  id="promociones" (click)="tendenciasSelect('promociones')">Promociones</a>
              </li>
              <li class="nav-item" style="padding: 0.5em 3em;">
                <a class="nav-link  btn-sm rounded-pill "  style="padding-left: 2.5em ; padding-right: 2.5em;" routerLinkActive="active" id="nuevos" type="button"  (click)="tendenciasSelect('nuevos')">Nuevos</a>
              </li>
              <li class="nav-item" style="padding: 0.5em ;">
                <a class="nav-link  btn-sm rounded-pill" type="button" id="grupos"   style="padding-left: 1.5em ; padding-right: 1.5em;" (click)="tendenciasSelect('grupos')">Categorías</a>
              </li>

            </ul>
          </div>
        </div>
      </nav>
</section>

<section id="menuSectionSM" style="display: none;">
    <h1 style="padding-top: 2em;" id="" style="font-size: 1.5rem;" >NUESTROS PRODUCTOS </h1>
    <div class="row divSelect ">
        <div class="col-7 p-1">    
            <a class="nav-link btn-sm rounded-pill text-center text-dark" aria-current="page" type="button"  (click)="tendenciasSelect('promociones')">Promociones</a>
        </div>
        <div class="col-7 p-1">    
            <a class="nav-link  btn-sm rounded-pill text-center text-dark "   routerLinkActive="active"  type="button"  (click)="tendenciasSelect('nuevos')">Nuevos</a>
        </div>
        <div class="col-7 p-1">    
            <a class="nav-link  btn-sm rounded-pill text-center text-dark" type="button"    (click)="tendenciasSelect('grupos')">Categorías</a>
        </div>
    </div>

    <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0 m-auto">
        <li class="nav-item " style="padding: 0.5em ;"  >
          <a class="nav-link btn-sm rounded-pill " style="padding-left: 1em ; padding-right: 1em;" aria-current="page" type="button"  id="promociones" (click)="tendenciasSelect('promociones')">Promociones</a>
        </li>
        <li class="nav-item" style="padding: 0.5em 3em;">
          <a class="nav-link  btn-sm rounded-pill "  style="padding-left: 2.5em ; padding-right: 2.5em;" routerLinkActive="active" id="nuevos" type="button"  (click)="tendenciasSelect('nuevos')">Nuevos</a>
        </li>
        <li class="nav-item" style="padding: 0.5em ;">
          <a class="nav-link  btn-sm rounded-pill" type="button" id="grupos"   style="padding-left: 1.5em ; padding-right: 1.5em;" (click)="tendenciasSelect('grupos')">Categorías</a>
        </li>

      </ul> -->

</section>


<section class="container-fluid">
   
   <!-- <app-card-component [productos]="productos" [configurationVariables]="configurationVariables" [configuracion]="configuracion" ></app-card-component> -->

    
   <owl-carousel-o [options]="productosPromoNuevos2">  
    <ng-template carouselSlide *ngFor="let p of productos; let i=index">
      <div class="row ">
        
           <div class="col  m-4" >
            <div class="card  box p-2">
                <div class="card-body " >
                    <div class="row">
                        <div class="col-5  divSelect" type="button" (click)="modalViewDetailProduct(p)">
                      
                                <img class=" " src="https://drive.google.com/uc?export=view&id={{p.imagenPrincipal}}" alt="" style="width: 100%; height: auto; object-fit: scale-down;  border-radius: 7px; ">
                        </div>
                        <div class="col-7" >
                            <div >

                            
                            <div class="product-text">
                              <!-- <h2>{{p.marca_nombre}}</h2> -->
                              <p>{{p.pro_nom}} </p>
                            </div>
                            <p class="text-size-11  mb-1 text-dorado divSelect">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                          </p>
                      

                          <div class="card-body text-minuscula pt-1 "  (click)="modalViewDetailProduct(p)">
                            <p class="text-size-14 text-center sin-espacios"
                                *ngIf="configurationVariables.mostrar_precio">
                                <span class="text-secondary mr-2 text-subrayado-gray"
                                    *ngIf="p.precioOferta > 0">${{p.precioOferta | number: '1.2'}} </span>
                                    <br>
                                <span style="font-family:'Helvetica' ; font-weight: bold;font-size: 1.3em; "> $ {{p.precioReal | number: '1.2'}} </span>
                            </p>
                            <p class="fw-bold  font-family='Helvetica'" style="font-size: 12px; color:#2478c1; text-align: center;">
                              Disponible en stock {{p.stockactual}}
                            </p>
                            
                            <p class="text-size-11 sin-espacios mt-1 pt-1 pb-1 bg-light rounded border border-warning text-center"
                                *ngIf="p.combo_venta_volumen">
                                <span *ngFor="let pric of p.precios">
                                    <strong *ngIf="pric.combo">
                                        {{pric.combo}}
                                        <br>
                                    </strong>
                                </span>
                            </p>
                            <!-- <div class="divSelect">
                                <button class="btn btn-sm btn-warning fw-bold  ">
                                    Agregar a  carrito
                                </button>
                            </div> -->
                        </div>
                            <div class=" button-group btn-group-sm divSelect ">
                                <button  style="padding: 0.35em 1em;" class="btn btn-warning rounded-pill m-1 fw-bold" id="btnAddCar{{i}}" title="Agregar a carrito" (click)="setMethodAddCart(p)">
                                  Agregar a carrito 
                                </button>
                                <!-- <button style="opacity: .50;"  class="btn bg-dark text-white rounded-circle m-1" id="btnAddCar{{i}}" title="Agregar a carrito" (click)="setMethodAddCart(p)">
                                  <i class="bi bi-cart4"></i>
                                </button>
                                <button   style="opacity: .50;" class="btn btn-warning rounded-circle m-1" id="btnVer{{i}}" title="Ver detalle producto" (click)="sharedFacebook(p)" >
                                  <i class="bi bi-share-fill"></i>
                                </button> -->
                    
                              </div>
                        </div>




                            
                        </div>
                    </div>
             
               
                 
                </div>
              </div>
           </div>


      </div>


</ng-template>

</owl-carousel-o>

    <!-- <hr class="linea-bottom-ploma"> -->
    <!-- <div *ngIf="productos.length > 0">
        <owl-carousel-o [options]="productosPromoNuevos">
            <ng-template carouselSlide *ngFor="let p of productos">

                <div class="mr-3">
                    <div class="card shadow p-3 mb-3 bg-white rounded div-product animate__animated animate__zoomIn">
                        <div class="w-100 text-center" (click)="modalViewDetailProduct(p)">
                            <img class="img-productos"
                                src="https://drive.google.com/uc?export=view&id={{p.imagenPrincipal}}" alt="">
                            <p class="text-size-11 mt-2 mb-1 text-dorado">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </p>
                        </div>

                        <div class="card-body text-minuscula pt-1 height-125 p-0">
                            <p class="text-size-14 text-center sin-espacios"
                                *ngIf="configurationVariables.mostrar_precio">
                                <span class="text-secondary mr-2 text-subrayado-gray"
                                    *ngIf="p.precioOferta > 0">${{p.precioOferta | number: '1.2'}} </span>
                                <span> $ {{p.precioReal | number: '1.2'}} </span>
                            </p>
                            <hr>
                            <p class="text-size-12 text-center sin-espacios">
                                {{p.pro_nom}}
                            </p>
                            <p class="text-size-11 sin-espacios mt-1 pt-1 pb-1 bg-light rounded border border-warning text-center"
                                *ngIf="p.combo_venta_volumen">
                                <span *ngFor="let pric of p.precios">
                                    <strong *ngIf="pric.combo">
                                        {{pric.combo}}
                                        <br>
                                    </strong>
                                </span>
                            </p>
                        </div>

                        <br>
                        <div class="row text-center border-top p-1" >
                            <div class="col-4 text-right div-icn-prod" (click)="setMethodAddCart(p)">
                                <i class="fas fa-cart-plus"></i>
                            </div>
                            <div class="col-4 text-center div-icn-prod" (click)="modalViewDetailProduct(p)">
                                <i class="fas fa-eye"></i>
                            </div>
                            <div class="col-4 text-left div-icn-prod" (click)="sharedFacebook(p)">
                                <i class="fas fa-share-alt"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div> -->
    <div *ngIf="groups.length > 0">
        <owl-carousel-o [options]="productosPromoNuevos">
            <ng-template carouselSlide *ngFor="let g of groups;let j=index">


                <div class="row ">
        
                    <div class="col  m-4" >
                     <div class="card1  box p-2">
                         <div class="card-body " >
                             <div class="row">
                                <div class="w-100 text-center" (click)="modalShowSubgrupos(showSubgrupos, g)" >
                                    <img class="img-productos" src="https://drive.google.com/uc?export=view&id={{g.img}}" alt="" *ngIf="g.img">
                                    <img class="img-productos" src="https://drive.google.com/uc?export=view&id=1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2" alt=""  *ngIf="!g.img">
                                    <!-- <p class="text-size-11 mt-2 mb-1 text-dorado">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </p> -->

                                 <div class="col-12" >
                                     <div class="product-text">
                                       <h2>{{g.nombre | uppercase}}</h2>
                                       <p>{{g.descripcion}} </p>
                                     </div>
                                     <div class=" button-group btn-group-sm divSelect">
  
                                        <button   style="opacity: .50;"  class="btn  bg-dark text-white rounded-circle m-1"  title="Ver detalle producto">
                                          <i (click)="modalShowSubgrupos(showSubgrupos, g)" class="bi bi-eye-fill"></i>
                                        </button>
                                        <button   style="opacity: .50;" (click)="sharedFacebookGroup(g)" class="btn btn-warning rounded-circle m-1" title="Compartir producto"  >
                                         <i class="bi bi-share-fill"></i>
                                       </button>
                                      
                                      </div>

                                 </div>
         

         
                                     
                                 </div>
                             </div>
                      
                        
                          
                         </div>
                       </div>
                    </div>
         
         
               </div>





                <!-- <div class="mr-3">
                    <div class="card shadow p-3 mb-3 bg-white rounded div-product animate__animated animate__zoomIn">
                        <p class="text-size-12 text-center mb-0 bb-0">{{g.nombre | uppercase}}</p>
                        <hr>
                        <div class="w-100 text-center" (click)="modalShowSubgrupos(showSubgrupos, g)">
                            <img class="img-productos" src="https://drive.google.com/uc?export=view&id={{g.img}}" alt="" *ngIf="g.img">
                            <img class="img-productos" src="https://drive.google.com/uc?export=view&id=1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2" alt=""  *ngIf="!g.img">
                            <p class="text-size-11 mt-2 mb-1 text-dorado">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </p>
                        </div>

                        <div class="card-body text-minuscula height-125 text-center">
                            <hr>
                            <span class="text-size-12">{{g.descripcion}}</span>
                        </div>

                        <hr>
                        <div class="row text-center ">
                            <div class="col-4 text-right div-icn-prod" (click)="modalShowSubgrupos(showSubgrupos, g)">
                                <i class="fas fa-eye"></i>
                            </div>
                            <div class="col-4 text-center div-icn-prod" (click)="modalShowSubgrupos(showSubgrupos, g)">
                                <i class="fa fa-heart text-danger"></i>
                            </div>
                            <div class="col-4 text-left div-icn-prod" (click)="sharedFacebookGroup(g)">
                                <i class="fas fa-share-alt"></i>
                            </div>
                        </div>
                    </div>
                </div> -->

            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="text-center" *ngIf="productos.length == 0 && groups.length == 0">
        <img class="mt-2 w-50" src="https://drive.google.com/uc?export=view&id=1X1L4AvE7SN3O_gIucIkmsc47pMASDdS2">
    </div>
</section>

<!-- Mostrar promociones creadas en la pagina web -->
<section class="container-fluid" *ngIf="promocionesWeb.promociones.length > 0">

    <div class="row mb-4 mt-4 pl-4 pr-4 text-center">
        <div class="col-lg-12 bg-light pt-3 pb-3" id="div-promo">
            <span class="text-mayuscula-titulos text-size-17"> {{promocionesWeb.titulo}}</span>
        </div>
    </div>

    <owl-carousel-o [options]="promocionWeb">
        <ng-template carouselSlide *ngFor="let p of promocionesWeb.promociones">

            <div class="row ">
        
                <div class="col  m-4" >
                 <div class="cardPROMO  box p-2">
                     <div class="card-body " >
                         <div class="row">
                            <div class="w-100 text-center" >
                                <div class="img-promocion-web divSelect" (click)="modalViewDetailPromotion(viewDetailPromotionModal, p)" >
                                    <img src="https://drive.google.com/uc?export=view&id={{p.imgPromocion}}" alt="" style=" border-radius: 10px; width: 60%; " >
                                </div>
                                <!-- <img class="img-productos" src="https://drive.google.com/uc?export=view&id={{g.img}}" alt="" *ngIf="g.img">
                                <img class="img-productos" src="https://drive.google.com/uc?export=view&id=1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2" alt=""  *ngIf="!g.img"> -->

                             <div class="col-12" >

                                 <hr>

                        <p class="text-minuscula-texto text-size-14" *ngIf="!p.view">
                            {{p.preliminar}}
                            <span class="text-dark tipo-cursor" *ngIf="p.vermas" (click)="seeMoreDescription(p)">
                                <i class="fas fa-eye text-size-15 ml-1"></i>
                            </span>
                        </p>
                        <p class="text-minuscula-texto text-size-14" *ngIf="p.view">
                            {{p.descripcion}}
                            <span class="text-dark tipo-cursor" (click)="seeMoreDescription(p)">
                                <i class="fas fa-eye-slash text-size-15 ml-2"></i>
                            </span>
                        </p>
                        <div class="product-text">
                            <h2>{{p.titulo | uppercase}}</h2>
                         
                          </div>

                             </div>
     

     
                                 
                             </div>
                         </div>
                  
                    
                      
                     </div>
                   </div>
                </div>
     
     
           </div>
            <!-- <div class="mr-3">
                <div class="img-promocion-web" (click)="modalViewDetailPromotion(viewDetailPromotionModal, p)" style="width: 100%; height: 250px; object-fit: scale-down;">
                    <img src="https://drive.google.com/uc?export=view&id={{p.imgPromocion}}" alt="" >
                </div>
                <div class="card centrar-div-horizontal shadow p-3 mb-3 bg-white rounded div-desc-promo">
                    <div class="card-body">
                        <p class="text-center text-mayuscula-titulos-normal text-size-15" *ngIf="p.titulo">{{p.titulo}}
                        </p>
                        <p class="text-center text-mayuscula-titulos-normal text-size-15" *ngIf="!p.titulo">Titulo de la
                            promoción</p>
                        <hr>

                        <p class="text-minuscula-texto text-size-14" *ngIf="!p.view">
                            {{p.preliminar}}
                            <span class="text-dark tipo-cursor" *ngIf="p.vermas" (click)="seeMoreDescription(p)">
                                <i class="fas fa-eye text-size-15 ml-1"></i>
                            </span>
                        </p>
                        <p class="text-minuscula-texto text-size-14" *ngIf="p.view">
                            {{p.descripcion}}
                            <span class="text-dark tipo-cursor" (click)="seeMoreDescription(p)">
                                <i class="fas fa-eye-slash text-size-15 ml-2"></i>
                            </span>
                        </p>
                    </div>
                </div>
            </div> -->
        </ng-template>

    </owl-carousel-o>

</section>

<section class="container-fluid">
    <!-- <app-menu-responsivo></app-menu-responsivo> -->
</section>

<!-- Modal enviar sugerencias -->
<ng-template #enviarSugerenciaModal let-modal>

    <form class="header-search-form text-minuscula">

        <div class="col-lg-12 text-center mt-3">

            <div class="row">
                <div class="col-10 text-size-14 text-size-16 text-secondary">
                    Envíe su sugerencia
                </div>
                <div class="col-2">
                    <i class="far fa-times-circle" (click)="modal.dismiss('Cross click')"></i>
                </div>
            </div>

        </div>

        <hr>

        <div class="modal-body">

            <div class="form-group">
                <p>
                    <i class="fas fa-id-card mr-2 text-secondary"></i>
                    <label class="text-size-14 mb-3">Nombres Completos *</label>
                    <input type="text" class="form-control form-control-sm" name="nombres"
                        [(ngModel)]="datosSugerencia.nombres">
                </p>
            </div>

            <div class="form-group">
                <p>
                    <i class="fas fa-envelope mr-2 text-secondary"></i>
                    <label class="text-size-14 mb-3">Correo Electrónico *</label>
                    <input type="text" class="form-control form-control-sm" name="correo"
                        [(ngModel)]="datosSugerencia.correo">
                </p>
            </div>

            <div class="form-group">
                <p>
                    <i class="fas fa-phone-square-alt mr-2 text-secondary"></i>
                    <label class="text-size-14 mb-3">Número de teléfono *</label>
                    <input type="text" class="form-control form-control-sm" name="telefono"
                        [(ngModel)]="datosSugerencia.telefono">
                </p>
            </div>

            <div class="form-group">
                <p>
                    <i class="fas fa-envelope mr-2 text-secondary"></i>
                    <label class="text-size-14 mb-3">Mensaje *</label>
                    <textarea class="form-control" rows="5" name="mensaje"
                        [(ngModel)]="datosSugerencia.mensaje"></textarea>
                </p>
            </div>

        </div>

        <div class="text-center container-fluid">
            <button type="button" class="btn btn-outline-dark text-size-14 btn-sm btn-block"
                (click)="modal.close(datosSugerencia)" id="btn-enviar-sugerencia">
                Enviar
            </button>
        </div>

        <br>
    </form>

</ng-template>

<!-- Modal mostrar descripcion de la promocion -->
<ng-template #viewDetailPromotionModal let-modal>
    <div class="row bg-light rounded">
        <div class="col-lg-6 text-center w-100 p-0">
            <img width="100%" height="100%"
                src="https://drive.google.com/uc?export=view&id={{promotionSelected.imgPromocion}}" alt="">
        </div>

        <div class="col-lg-6 text-center w-100 p-0">
            <div class="text-right p-3">
                <i class="far fa-times-circle text-danger" (click)="modal.dismiss('Cross click')"></i>
            </div>
            <div class="text-center p-0">
                <div>
                    <span class="text-size-20 text-mayuscula-titulos" *ngIf="promotionSelected.titulo">
                        {{promotionSelected.titulo}}</span>
                    <span class="text-size-20 text-mayuscula-titulos" *ngIf="!promotionSelected.titulo"> Titulo de la
                        promoción </span>
                </div>

                <hr>
                <div class="text-justify pl-3 pr-3">
                    <span class="text-minuscula-texto text-size-14">
                        {{promotionSelected.descripcion}}
                    </span>
                </div>

                <hr>
                <div class="mt-5 mb-5 pl-3 pr-3">
                    <button type="button" class="btn btn-circle animate__animated animate__jackInTheBox"
                        id="btn-go-promotion" (click)="modal.close(promotionSelected)"><i
                            class="fa fa-arrow-circle-right"></i></button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Alerta para visualizar los subgrupos -->
<ng-template #showSubgrupos let-modal>
    <div class="row text-minuscula pl-2 pr-2 pb-4 bg-light text-size-16">
        <div class="col-lg-12 mt-3">
            <div class="row">
                <div class="col-12 pt-2 pb-2 rounded border alert alert-dark">
                    <span class="float-left text-size-20"><i class="fa fa-th-large text-light mr-2" aria-hidden="true"></i> {{groupSelected.nombre | uppercase}}</span>
                    <span class="float-right"><i class="far fa-times-circle text-danger" (click)="modal.dismiss('Cross click')"></i></span>
                </div>
            </div>
        </div>

        <div class="col-lg-12">
            <div class="row pl-3 pr-3">
                <div class="col-lg-4 mt-3" *ngFor="let s of groupSelected.subgrupos">
                    <div class="card shadow p-3 mb-3 bg-white rounded div-product animate__animated animate__zoomIn">
                        <div class="w-100 text-center" (click)="modal.close(s)">
                            <img class="img-productos" src="https://drive.google.com/uc?export=view&id={{s.img}}" alt=""
                                *ngIf="s.img">
                            <img class="img-productos"
                                src="https://drive.google.com/uc?export=view&id=1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2" alt=""
                                *ngIf="!s.img">
                            <p class="text-size-11 mt-2 mb-1 text-dorado">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </p>
                        </div>

                        <div class="card-body text-minuscula pt-1 height-75">
                            <hr>
                            <p class="text-size-12 text-center pl-0 pr-0">
                                {{s.nombre | uppercase}}
                            </p>
                        </div>

                        <hr>
                        <div class="row text-center ">
                            <div class="col-12 text-center div-icn-prod" (click)="modal.close(s)">
                                <i class="fas fa-eye"></i>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<app-scroll-to-top></app-scroll-to-top>

<app-footer></app-footer>
