import { AfterContentInit, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ServicesService } from '../../shared/services/services.service';
declare function paginadorCatalogo():any;
@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoComponent implements OnInit , AfterContentInit {

  public empresa: any;

  public loadingAll = false;
  public loadingAllCategory = false;

  public informacion: any;

  public configuracion: any;

  public closeResult: string;

  public grupos: [];
  public subgrupos: [];

  public atributes_config = {
    viewBtnPagnation: false
  }

  constructor(
    private toaster: ToastrService,
    private webService: ServicesService,
    public router: Router,
    private modalCtrl: NgbModal,
    private activateRoute: ActivatedRoute,

  ) {
 
  }

  async ngOnInit() {
    await this.getDataEmpresa();
    await this.getConfiguracion();
  

  }

  ngAfterContentInit(): void {
      // menuCategorias()
      paginadorCatalogo();
  }



  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  public async getDataEmpresa() {
    await this.webService.getEmpresaService().then(async (resEmpr: any) => {
      this.empresa = resEmpr.data[0];
      // console.log("Empres", this.empresa);
    });
  }

  async getConfiguracion() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      this.configuracion = data[0];
      // Boton Categorias
      // let bcat = document.getElementById('btn-categorias');
      // bcat.style.background = this.configuracion.colorPrincipal;
      // bcat.style.color = this.configuracion.colorLetra;

      //span MENU CATEGORIAS
      let menuCat = document.getElementById('div-catalogo2');
      menuCat.style.background = this.configuracion.colorPrincipal;
      menuCat.style.color = this.configuracion.colorLetra;
      //span MENU CATEGORIAS

      
      // for (let i = 0; i < this.grupos.length; i++) {
      //  console.log("ENTRA");
      //  let flecha = document.getElementById('colorArrow'+i);
      //  flecha.style.background = this.configuracion.colorPrincipal;
      //  flecha.style.color = this.configuracion.colorLetra;
      // }




      // Obtener las categorias
      await this.webService.getTemporaryCatalogue().then(async (rescatalogue: any) => {
        if (rescatalogue) {
          this.grupos = rescatalogue;
          // console.log('sub AQUI', this.grupos);
          
          this.viewButtonPagination();
        } else {
          await this.getGrupos(this.empresa, data[0].tipo_web);
        }
      });
    });
  }

  // tipoWeb = 1 => Tienda normal
  // tipoWeb = 2 => Tienda por tallas
  // tipoWeb = 3 => Tienda con 2 BD
  // tipoWeb = 4 => Tienda Internet
  async getGrupos(empresa, tipo_web) {
    if (tipo_web == 1 || tipo_web == 2 || tipo_web == 4) {
      this.loadingAll = true;
      await this.webService.getGruposService(empresa.url_billing, 'filter').then(async (resCategorias: any) => {
        // console.log('print',resCategorias);
        
        if (!resCategorias.error) {
          if (resCategorias.rta == true) {
            await this.getSubgrupos(resCategorias.data, empresa.url_billing).then(async (resSubgrup: any) => {
              await this.webService.saveTemporaryCatalogue(resSubgrup);
              this.grupos = resSubgrup;  
           
              await this.viewButtonPagination();
            });
          } else {
            this.toaster.warning('Catálogo de la tienda vacio', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        } else {
          this.toaster.error('Error al obtener las categorias', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
      this.loadingAll = false;

    }

    if (tipo_web == 3) {
      console.log("Tienda con 2 BD, codigo se encuentra en proceso");
    }

  }

  async getSubgrupos(grupos, url_billing) {
    this.subgrupos = [];
    this.loadingAll = true;
    for (let g of grupos) {
      g.nombre = await this.webService.convertStringTypeSentence(g.nombre);
      await this.webService.getSubgruposService(url_billing, g.idgrupo).then(async (resSubgrup: any) => {
        g.subgrupos = resSubgrup.data;
      });
    }
    this.loadingAll = false;
    return grupos;
  }

  async getProductos(grupo, subGrupo) {
    let type = 'selectCatalogue';
    this.router.navigateByUrl('product/' + type + '/' + grupo.idgrupo + '/' + subGrupo.id_sub);
    
  }

  async paginationCatalogue(grupos, type) {
    await this.webService.paginationCatalogueGroup(grupos, type).then((respagi) => {
      grupos = respagi;
    });
  }

  async viewButtonPagination() {
    if (this.grupos.length > this.webService.size) {
      this.atributes_config.viewBtnPagnation = true;
      // let ul = document.getElementById('div-pagination');
      // ul.style.color = this.configuracion.colorPrincipal;
    }
  }

  // ============================== NUEVO CODIGO ====================================

  desplegar(){
    // menuCategorias()
  }
}
