import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ServicesService } from '../../../shared/services/services.service';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public closeResult: string;
  public loading = false;
  public configuracion: any = {};
  public user: any = {};
  public provinces: any = [];
  public cantones: any = [];
  public cotizationsPending: any = [];
  public cotizationsProcessed: any = [];
  public cotizationsSelected: any = {};
  public messageDelete = {
    cotization: 'Seguro que desea cancelar este pedido'
  };


  public menu = {
    miPerfil: true,
    comprasPendientes: false,
    comprasProcesadas: false,
    credenciales: false
  }


  constructor(
    private webService: ServicesService,
    private toaster: ToastrService,
    private modalCtrl: NgbModal,
  ) { }

  async ngOnInit() {
    this.loading = true;
    await this.getConfiguration();
    await this.verifyLoginClient();
    await this.getCotizationsClient();
    this.loading = false;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async verifyLoginClient() {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
      if (login.rta == true) {
        if (login.data.rol != 'Client') {
          this.webService.goAdminProfile();
        } else {
          await this.webService.getUrlEmpresa().then(async (url) => {
            await this.webService.getDataUserByCedula(url, login.data.PersonaComercio_cedulaRuc).then(async (resuser: any) => {
              if (resuser.rta == true) {
                this.user = resuser.data;
              } else {
                console.log("No se ha encontrado el usuario");
              }
            });
            await this.webService.getProvincesEcuador(url).then(async (resprovinces: any) => {
              if (resprovinces.rta == true) {
                await this.webService.stablishProvinceAndCantonClient(this.user, resprovinces.data, 'province').then(async (res: any) => {
                  this.provinces = res.data;
                  if (res.id != 0) {
                    await this.getCantonesProvince(res.id);
                  }
                });
              } else {
                this.provinces = [];
              }
            });
          });
        }
      } else {
        this.webService.goHome();
      }
    });
  }

  async getCantonesProvince(id_province) {
    let query = {
      'tabla': 'bill_canton',
      'atributo': 'bill_provincia_idProvincia',
      'valor_atributo': id_province,
      'filas': '*'
    };
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getCantonesProvince(url, query).then(async (rescantones: any) => {
        if (rescantones.rta == true) {
          await this.webService.stablishProvinceAndCantonClient(this.user, rescantones.data, 'canton').then(async (res: any) => {
            this.cantones = rescantones.data;
          });
        } else {
          this.cantones = [];
        }
      });
    });
    this.loading = false;
  }

  async goHome() {
    await this.webService.goHome();
  }

  async signOff() {
    await this.webService.signOuth(this.configuracion.loginStorage).then(async (resClose: any) => {
      this.goHome();
    });
  }

  async getConfiguration() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        if (data[0]) {
          this.configuracion = data[0];
        } else {
          console.log("No se ha encontrado configuracion");
        }
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async selectMenu(type) {
    this.menu = {
      miPerfil: false,
      comprasPendientes: false,
      comprasProcesadas: false,
      credenciales: false
    }
    if (type == 'miPerfil') {
      this.menu.miPerfil = true;
    }
    if (type == 'comprasPendientes') {
      if (this.cotizationsPending.length > 0) {
        // console.log("Pending", this.cotizationsPending);
        this.menu.comprasPendientes = true;
      } else {
        this.menu.miPerfil = true;
        this.toaster.warning('No posee compras pendientes', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    }
    if (type == 'comprasProcesadas') {
      if (this.cotizationsProcessed.length > 0) {
        // console.log("Processed", this.cotizationsProcessed);
        this.menu.comprasProcesadas = true;
      } else {
        this.menu.miPerfil = true;
        this.toaster.warning('No posee compras realizadas', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    }
    if (type == 'credenciales') {
      this.menu.credenciales = true;
    }
  }

  async updateUser(user) {
    this.loading = true;
    await this.webService.validateCustomerData(user).then(async (resuser: any) => {
      if (resuser.rta == true) {
        if (this.webService.validateEmail(user.email) == true) {
          if (this.webService.validateNumbers(user.celular) == true) {
            user.nombres = this.webService.convertMayuscula(user.nombres);
            user.apellidos = this.webService.convertMayuscula(user.apellidos);
            await this.webService.getUrlEmpresa().then(async (url) => {
              let send = {
                data: user,
                endPoint: url + "update_all_client"
              }
              await this.webService.updateUserData(send).then(async (resupd: any) => {
                if (resupd.rta == true) {
                  await this.webService.saveUserLocalStorage(user, this.configuracion.loginStorage, 'Client');
                  this.toaster.success('Información actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                } else {
                  this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                }
              });
            });
          } else {
            this.toaster.error('Número de celular no válido', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        } else {
          this.toaster.error('Correo electrónico no válido', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      } else {
        this.toaster.error('Campos vacios, verifíque e intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
    this.loading = false;
  }

  async getCotizationsClient() {
    this.cotizationsPending = [];
    this.cotizationsProcessed = [];
    let query = {
      cedula: this.user.PersonaComercio_cedulaRuc,
      desde: '',
      hasta: ''
    }
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getCotizationsClient(url, query).then(async (rescot: any) => {
        if (!rescot.error) {
          for (let c of rescot.data) {
            if (c.estado_factura == 0) {
              this.cotizationsPending.push(c);
            }
            if (c.estado_factura == 1) {
              this.cotizationsProcessed.push(c);
            }
          }
        } else {
          console.log("Error al conectarse con el servidor");
        }
      });
    });

    this.cotizationsPending = this.cotizationsPending.reverse();
    this.cotizationsProcessed = this.cotizationsProcessed.reverse()
  }

  async deleteCotization(c) {
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.deleteCotization(url, c.id).then(async (resdel: any) => {
        if (resdel.rta == true) {
          for (let p of this.cotizationsPending) {
            if (p.id == c.id) {
              this.cotizationsPending = this.cotizationsPending.filter(function (i) { return i !== p });
            }
          }
          this.toaster.success('Pedido cancelado con éxito', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.loading = false;
  }

  async modalDeleteFile(deleteFileModal, data, type) {
    this.modalCtrl.open(deleteFileModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      if (type == 'cotization') {
        await this.deleteCotization(data);
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async modalShowDetailCotization(showDetailCotizationModal, cotization) {
    this.cotizationsSelected = cotization;
    this.modalCtrl.open(showDetailCotizationModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.cotizationsSelected = {};
    });
  }
}
