import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicesService } from 'src/app/shared/services/services.service';
import { LoginAdminComponent } from '../login/login-admin/login-admin.component';
import { LoginUserComponent } from '../login/login-user/login-user.component';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
  public videoYoutube;
  public configuracion: any;
  public videoY:any;
  banderaCardVideo = false;

  public cartProducts = {
    number: 0,
    total: 0.00
  }
  public closeResult: string;
  public loadingAll = false;
  public clientLogin = {
    name: '',
    imagen: '',
    login: false,
    rol: ''
  }
  public companyNane;
  public textoValores:any;
  public searchProd = '';
  public textWhatsapp = '';


  constructor(
    private webService: ServicesService,
    private modalCtrl: NgbModal,
    private toaster: ToastrService,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) { }

  async ngOnInit() {

    this.loadingAll = true;
    await this.getConfiguracion();
    await this.getInformacion();
    await this.webService.observableLoginUser().subscribe((resLog: any) => {
      this.clientLogin = {
        name: resLog.name,
        imagen: resLog.imagen,
        login: resLog.login,
        rol: resLog.rol
      }
      // console.log("suscritoo", this.clientLogin);
    });
    // Suscribirse a cambios del carrito
    await this.webService.observableProductsCart().subscribe((rescart: any) => {
      // console.log("suscrito car", rescart);
      this.cartProducts = {
        number: rescart.number,
        total: rescart.total
      }
    });
    this.loadingAll = false;
  }

  //   async getInformacion() {
  //   this.webService.getInformacion().then(async (dataInfo: any) => {
  //     this.informacion = dataInfo[0];
  //     if (dataInfo[0].video) {
  //       this.videoYoutube = this.webService.getIdVideoYoutube(dataInfo[0].video);
  //     } else {
  //       this.videoYoutube = '';
  //     }
  //   });
  // }

  async getInformacion() {
    await this.webService.getInformacion().then((resinfo: any) => {
      // console.log(resinfo);
      
      this.companyNane = resinfo[0].nombre;
      this.textoValores = resinfo[0].valores;
      // console.log(this.textoValores);
      
      if (resinfo[0].video) {
        this.videoYoutube = this.webService.getIdVideoYoutube(resinfo[0].video);
      } else {
        this.videoYoutube = '';
      }

      let video = resinfo[0].video;
      if(video==''){
        this.banderaCardVideo = false;
      }else{
        this.banderaCardVideo = true;
      }
      // console.log('video', video);
      let urlV =  video.slice(32)
      let url = 'https://www.youtube.com/embed/'+urlV;
      this.videoY = url;
      // this.videoY = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      // console.log('url', this.videoY);
      


      // console.log('url', 'https://www.youtube.com/embed/'+this.videoY);
      
      //       if ( resinfo[0].video) {
      //   this.videoYoutube = this.webService.getIdVideoYoutube(  resinfo[0].video);
      //   this.videoYoutube =  this.videoYoutube.slice(32);

      //   console.log('lo que queda', this.videoYoutube);
        
        
      // } else {
      //   this.videoYoutube = '';
      // }
    });
  }

  async getConfiguracion() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      // console.log('data', data);
      
      this.configuracion = data[0];

      let dsm = document.getElementById('div-submenu');
      dsm.style.background = this.configuracion.colorPrincipal;
      dsm.style.color = this.configuracion.colorLetra;

      // Boton Search
      // let bs = document.getElementById('btn-search');
      // bs.style.background = this.configuracion.colorPrincipal;
      // bs.style.color = this.configuracion.colorLetra;

      // Boton Carrito
      let bc = document.getElementById('btn-car');
      bc.style.background = this.configuracion.colorPrincipal;
      bc.style.color = this.configuracion.colorLetra;

      // Boton Carrito 3
      let bc3 = document.getElementById('btn-car3');
      bc3.style.background = this.configuracion.colorPrincipal;
      bc3.style.color = this.configuracion.colorLetra;

      //NAVBAR 
      let navbar = document.getElementById('navBarC');
      navbar.style.background = this.configuracion.colorLetra;
      // navbar.style.color = this.configuracion.colorLetra;

      //NAVBAR 
      let bs2 = document.getElementById('btn-search2');
      // bs2.style.background = this.configuracion.colorPrincipal;
      // bs2.style.color = this.configuracion.colorPrincipal;
      //NAVBAR 
      let   opNav = document.getElementById('optionNav');
      opNav.style.color = '#666666'
      let   opNav1 = document.getElementById('optionNav1');
      // opNav1.style.color = this.configuracion.colorLetra;
      opNav1.style.color = '#666666';
      let   opNav2 = document.getElementById('optionNav2');
      opNav2.style.color = '#666666'
      let   opNav3 = document.getElementById('optionNav3');
      opNav3.style.color = '#666666'
      let   opNav4 = document.getElementById('optionNav4');
      opNav4.style.color = '#666666'
      // let   opNav5 = document.getElementById('optionNav5');
      // opNav5.style.color = '#666666'
      //titulo Portada Inicio
      let   titlePortada = document.getElementById('tituloPortada');
      titlePortada.style.color = this.configuracion.colorPrincipal;
      //Button Ecommerce pag Inicio
      let   btnEcommerce = document.getElementById('btnEcommerce');
      btnEcommerce.style.background = this.configuracion.colorPrincipal;
      btnEcommerce.style.color = this.configuracion.colorLetra;



      await this.isAutenticatedClient(this.configuracion).then(async (resauth: any) => {
        // Obtener productos del carrito y su total
        if (resauth.rta == true) {
          await this.webService.getproductsCart({ id_cliente: resauth.data.PersonaComercio_cedulaRuc }).then(async (resprod: any) => {
            this.cartProducts = {
              number: resprod.data.length,
              total: await this.webService.calculateTotalCartProducts(resprod.data)
            }
          });
        }
      });
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  async loginUser() {
    this.modalCtrl.open(LoginUserComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
  }

  async loginAdmin() {
    this.modalCtrl.open(LoginAdminComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
  }
  async signOff() {
    await this.webService.signOuth(this.configuracion.loginStorage).then((resClose: any) => { });
    this.cartProducts = {
      number: 0,
      total: 0.00
    }
    await this.webService.goHome();
    await this.webService.refreshPage(this.configuracion);
  }

  async goClientProfile() {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (reslogin: any) => {
      if (reslogin.rta == true) {
        if (reslogin.data.rol == 'Client') {
          this.webService.goUserProfile();
          // console.log("ar al cliente");
        }
        if (reslogin.data.rol == 'Administrator') {
          this.webService.goAdminProfile();
          // console.log("ar al administrador");
        }
      }
    });
  }

  async goHome() {
    // this.webService.goHomeClean();
    this.webService.goHome();
    
  }
  async goHome2() {
    // this.webService.goHomeClean();
    // this.webService.goHome();
    // window.open('https://www.ruedasygarruchas.com/', "_self");    
    // window.open('http://publigorras.com.ec', "_self");
  }
  async goProductsCatalogue() {
    this.webService.goProductsCatalogue();
  }

  async goProductsCatalogue2() {
    // console.log("AQUI desde inicio");
    this.webService.goProducts1();
  }
  async goShoppingCartUser() {
    await this.isAutenticatedClient(this.configuracion).then(async (resauth: any) => {
      if (resauth.rta == true) {
        if (this.cartProducts.number > 0) {
          await this.webService.goShoppingCart();
        } else {
          this.toaster.warning('Su carrito de compras esta vacio', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      } else {
        await this.loginUser();
      }
    });
  }

  async isAutenticatedClient(configuracion) {
    let auth;
    await this.webService.isAuthenticatedClient(configuracion.loginStorage).then((login: any) => {
      auth = login;
      if (login.rta == true) {
        this.clientLogin = {
          name: login.data.nameUser,
          imagen: login.data.imagen,
          login: true,
          rol: login.data.rol
        }
      } else {
        this.clientLogin = {
          name: '',
          imagen: '',
          login: false,
          rol: ''
        }
      }
    });
    return auth;
  }

  scrollToElement($element:any): void {
    // console.log($element);
    setTimeout(() => {
      $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      // this.listarTodos1();    
     }, 300);
  }


}
