<section class="container-fluid">
    <!-- Administrador, login usuario -->
    <div class="row text-size-12 " id="div-submenu">
        <div class="col-lg-6 pt-2 pb-2 ">
            <div class="pl-3">
                <span style="float: left;">
                    Bienvenidos a <strong>{{companyNane}}</strong> Tienda Online
                </span>
            </div>
        </div>

        <div class="col-lg-6 pt-2 pb-2 div-login-user">
            <div class="pr-3" *ngIf="!clientLogin.login">
                <div class="div-oauth" title="Ingresar como Administrador">
                    <span class="ml-3 spn-user-login" role="button" (click)="loginAdmin()">
                        Administrador
                        <i class="fas fa-user-cog ml-2"></i>
                    </span>
                </div>
                <div class="div-auth">
                    <span class="pr-3 linea-right-blanca spn-user-login" role="button" (click)="loginUser()">
                        <i class="fas fa-user-lock mr-2"></i>
                        Iniciar Sesión
                    </span>
                </div>
            </div>

            <div class="pr-3" *ngIf="clientLogin.login">
                <div class="div-oauth" (click)="signOff()">
                    <span class="ml-3 spn-user-login" role="button">
                        <i class="fas fa-lock ml-2"></i>
                    </span>
                </div>
                <div class="div-auth" (click)="goClientProfile()">
                    <img src="../../../assets/img/user.jpg" class="rounded-circle mr-2" width="25px" height="25px"
                        *ngIf="!clientLogin.imagen">
                    <img src="https://drive.google.com/uc?export=view&id={{clientLogin.imagen}}"
                        class="rounded-circle mr-2" width="25px" height="25px" *ngIf="clientLogin.imagen">
                    <span class="pr-3 linea-right-blanca spn-user-login" role="button">
                        {{clientLogin.name}}
                    </span>
                </div>
            </div>
        </div>
    </div>


    <!-- NAVBAR 2 -->

    <div class="row " id="navBarC" style="   box-shadow: 1px 3px 4px 2px rgba(178, 177, 177, 0.3); ">
        <div class="col-lg-2 col-md-12 col-sm-12" (click)="goHome2()">
            <a class="navbar-nav "   >
                <img  class="p-2" src="https://drive.google.com/uc?export=view&id={{configuracion?.imgLogo}}" style="width: 100%;height: 80px; object-fit: scale-down; ">
            </a>
        </div>
        <div class="col-lg-8 col-md-12 col-sm-8" >
            <div class="row">
                <div class="col-12">
                    <section id="sectionLG" >
                        <nav class="">
                          <ul class="menuItems divSelect pt-3">
                            <li>
                                <a id="optionNav" class="nav-link active" [routerLink]="[ '/inicio' ]" routerLinkActive="active"  aria-current="page"  type="button"  ><i class="bi bi-house-fill"></i> Inicio</a>

                            </li>
                            <li>
                                <a id="optionNav1" class="nav-link active" aria-current="page"  type="button" (click)="goHome()"  > E-commerce</a>

                            </li>
                            <li>
                                <a id="optionNav2" class="nav-link" type="button" (click)="goProductsCatalogue2()"   >Productos</a>

                            </li>
                            <li>
                                <a id="optionNav3" class="nav-link" type="button"  (click)="scrollToElement(secAboutUS)" >Sobre nosotros</a>

                            </li>

                            <li>
                                <a id="optionNav4" class="nav-link" type="button"  (click)="scrollToElement(secFooter)">Contáctanos</a>
                            </li>
                          </ul>
                        </nav>
                      
                      </section>
                      <section id="sectionXS" >
                        <div class="row">
                            <div class="col" style="padding-left: 2em;">
                                <ul class="p-2 " style=" list-style: none; font-weight: 500; ">
                                    <li>
                                        <a id="optionNav" class="nav-link active" [routerLink]="[ '/inicio' ]" routerLinkActive="active"  aria-current="page"  type="button"  ><i class="bi bi-house-fill"></i> Inicio</a>

                                    </li>
                                    <li> 
                                        <a id="optionNav1" class="nav-link active" aria-current="page"  type="button" (click)="goHome()"  > E-commerce</a>
                                    </li>
                                    <li> 
                                        <a id="optionNav2" class="nav-link" type="button" (click)="goProductsCatalogue2()"   >Productos</a>
    
                                    </li>
                                    <li> 
                                        <a id="optionNav3" class="nav-link" type="button"  (click)="scrollToElement(secAboutUS)" >Sobre nosotros</a>

                                    </li>
                                    <li> 
                                        <a id="optionNav4" class="nav-link" type="button"  (click)="scrollToElement(secFooter)">Contáctanos</a>
    
                                    </li>
                                </ul>
                            </div>
                            <div class="col" (click)="goShoppingCartUser()">
                                <div class="row pt-2">
                
                                    <div class="col-6 " style="padding: 0em 0.65em 0em 0em;">
                    
                                        <button type="button" class="btn-circle  rounded-circle float-right animate__animated animate__swing" id="btn-car3">
                                          
                                            <i class="bi bi-cart4 fw-bold" style="width: 100%; height: auto;object-fit: cover ;" ></i>
                                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger p-1">
                                             
                                                {{cartProducts.number}}
                                           
                                            </span>
                                          </button>
                                    </div>
                    
                                    <div class="col-6 linea-left-ploma " style="padding: 0em 0em 0em 0.65em;">
                                        <div class="row" (click)="goShoppingCartUser()">
                                            <div class="col-12">
                                                <span class="float-left fw-bold " >CARRITO</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="float-left">$ {{cartProducts.total | number: '1.2'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <ul class="p-2">
                            <li><i class="bi bi-house-fill"></i> Inicio</li>
                            <li> Ecommerce </li>
                            <li> Productos </li>
                            <li> Sobre nosotros </li>
                            <li> Contáctanos </li>
                        </ul> -->
                      </section>
                </div>

            </div>

          
        </div>
        <div class="col-lg-2 col-4" id="carritoXS" style="padding-top: 1em;" (click)="goShoppingCartUser()" >
            <div class="row pt-2">
                
                <div class="col-6 " style="padding: 0em 0.65em 0em 0em;">

                    <button type="button" class="btn-circle  rounded-circle float-right animate__animated animate__swing" id="btn-car">
                      
                        <i class="bi bi-cart4 fw-bold" style="width: 100%; height: auto;object-fit: cover ;" ></i>
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger p-1">
                         
                            {{cartProducts.number}}
                       
                        </span>
                      </button>
                </div>

                <div class="col-6 linea-left-ploma " style="padding: 0em 0em 0em 0.65em;">
                    <div class="row" (click)="goShoppingCartUser()">
                        <div class="col-12">
                            <span class="float-left fw-bold " >CARRITO</span>
                        </div>
                        <div class="col-12">
                            <span class="float-left">$ {{cartProducts.total | number: '1.2'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

       <!-- NAVBAR 3 -->
   



    

</section>

<!-- <section>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">{{companyNane}}</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">INICIO</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">ECOMMERCE</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">PRODUCTOS</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">SOBRE NOSOTROS </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">CONTACTO </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
</section> -->

<section id="mainScreen2"  style="background-image: url(https://drive.google.com/uc?export=view&id={{configuracion?.imgFooter}});">
<!-- <section id="mainScreen2"  > -->

    <div class="row">
        <div class="col-lg-6 col-md-8 col-12 " style="padding-top: 11%;">
            <div class="divSelect">
                <span style="font-size:  xx-large; " id="tituloPortada">
                    <strong>{{companyNane}}</strong>
               </span>
            </div>


        </div>

    </div>
    <div class="row">

        <div class="col-lg-6 col-md-8 col-12" >
            <div class="divSelect">
                <p class="p-5">
                    <!-- Empresa dedicada a la venta de insumos para la reposteria, realizamos envíos a todo el Ecuador -->
                    {{textoValores}}
                </p>

            </div>

        </div>

    </div>

    <div class="row">
        <div class="col-lg-6 col-md-8 col-12 " >
            <div class="divSelect">
                <button class="btn rounded-pill fw-bold" id="btnEcommerce" (click)="goHome()" >
                    Ir a E-COMMERCE
                </button>
            </div>

        </div>
    </div>


</section>
<!-- <div style="background-color:red ; z-index: 1;">
    <div class="text-center pt-4" >
        <span style="font-size:  3rem; z-index: 2; color: white; font-family: 'Helvetica'; " >
            <strong>{{companyNane |titlecase}}</strong>
       </span>
    </div>


    <div class=" " style="padding-top: 5em; z-index: 4;">
        <div class="row ">
            <div class="col">

            </div>
            <div class="col">
        <iframe width="100%" height="475" src="https://www.youtube.com/embed/UyzmclzAor0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div>
            <div class="col">

            </div>
            
        </div>
    </div>



</div> -->
<section #secAboutUS>
    <app-about-us></app-about-us>
</section>

<div class="mainScreen" *ngIf="banderaCardVideo">
<!-- <div class="mainScreen" > -->
    <!-- <h1>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem ipsa error possimus earum eos debitis, in sint laborum velit vero soluta laboriosam eum neque voluptatum, ut ducimus natus consequuntur blanditiis!</h1> -->

    <div class="bgHeader" style=" background-image: linear-gradient(rgba(9, 8, 8, 0.5), rgba(0, 0, 0, 0.5)),url(https://drive.google.com/uc?export=view&id={{configuracion?.imgFooter}})">
        <div class="" id="headerCEspecializadadLG">
        <div class="row w-100">

            <div class="col-lg-12 col-12 p-5">
                <div class="text-center pt-4" >
                    <span style="font-size:  1.8rem; z-index: 2; color: white; font-family: 'Helvetica';font-style: italic; " >
                        <!-- <strong>{{companyNane |titlecase}}</strong> -->
                        <strong>''{{companyNane |titlecase}}''</strong>
                   </span>
                </div>

            </div>
            <div class="col-lg-12">

  
            <div class="row">
                <div class="col">
                    <div class="divSelect">
                        <!-- <youtube-player [videoId]="videoYoutube" width="100%" height="auto"></youtube-player> -->

                        <iframe width="560" height="315" [src]="videoY | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>

            </div>
        </div>

        </div>


    </div>
       
        
    </div>



</div>
<div class="col" #secFooter>
           
    <app-footer></app-footer>
</div>


