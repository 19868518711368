import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { ServicesService } from '../../shared/services/services.service';
import { LoginUserComponent } from '../login/login-user/login-user.component';
import { DetailProductComponent } from '../../shared/detail-product/detail-product.component';

let apiLoaded = false;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  public configuracion: any = {};
  public login: any = {};
  public client: any = {};
  public products: any = [];
  public productsSold: any = {};
  public configurationVariables = {
    tipo_precio: 'pA', // No se esta usando
    mostrar_precio: 1,
    show_attributes_prod: false,
  }
  public loadingAll = false;
  public productSelected: any;
  public closeResult: string;
  public guarnition: any = [];


  constructor(
    private toaster: ToastrService,
    private webService: ServicesService,
    private activateRoute: ActivatedRoute,
    private modalCtrl: NgbModal,
    private meta: Meta,
    private router: Router,
  ) {
    // this.showProducts();

  }

  async ngOnInit() {
    // Cargar atributos para el video de youtube
    const tag = await document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    await document.body.appendChild(tag);
    apiLoaded = true;
    let url;
    await this.getConfiguracion();
    await this.webService.getUrlEmpresa().then(async (url_billing) => {
      url = url_billing;
    });
    await this.activateRoute.params.subscribe(async (params: Params) => {
      this.products = [];
      this.loadingAll = true;
      await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (reslogin: any) => {
        this.login = reslogin;
      });
      if (params.value) {
        if (params.type == 'search') {
          let search = params.value;
          await this.webService.searchProduct(url, search, this.configuracion).then(async (ressearch: any) => {
            if (ressearch.rta == true) {
              this.products = [];
              if (this.configuracion.tipo_web == 1) {
                await this.webService.obtainAndCalculatePriceProduct(ressearch.data, this.configuracion, this.login).then(async (resPrice) => {
                  this.products = resPrice;
                  // console.log("search", this.products);
        
                });
              }
              if (this.configuracion.tipo_web == 2) {
                await this.webService.obtainAndCalculatePriceProduct(ressearch.data, this.configuracion, this.login).then(async (resPrice) => {
                  await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                    this.products = resTalla;
                  });
                });
              }
            } else {
              search = '';
              this.toaster.warning('No se ha encontrado coincidencias', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        }

        if (params.type == 'selectCatalogue') {
          this.webService.getProductosService(url, params.value, params.value2, this.configuracion).then(async (resprod: any) => {
            // console.log("esto es", resprod);
            if (resprod.rta == true) {
              if (this.configuracion.tipo_web == 1) {
                await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                  this.products = resPrice;
                  
                  // console.log("seleccionado", this.products);
                });
              }

              if (this.configuracion.tipo_web == 2) {
                await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                  await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                    this.products = resTalla;
                  });
                });
              }
            } else {
              this.toaster.warning('No se ha encontrado productos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        }

        if (params.type == 'selectProduct') {
          await this.webService.getProductosCodigoService(url, params.value, this.configuracion).then(async (resprod: any) => {
            if (resprod.rta == true) {
              if (this.configuracion.tipo_web == 1) {
                await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                  this.products = resPrice;
                  // console.log("llamado", this.products);
                });
              }
              if (this.configuracion.tipo_web == 2) {
                await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                  await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                    this.products = resTalla;
                    console.log("ACA", this.products);
                  });
                });
              }
            } else {
              this.toaster.warning('No se ha encontrado productos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        }
      } else {
        await this.webService.getTemporaryCatalogue().then(async (rescat: any) => {
          if (rescat) {
            await this.webService.getProductosService(url, rescat[0].idgrupo, rescat[0].subgrupos[0].id_sub, this.configuracion).then(async (resprod: any) => {
              if (resprod.rta == true) {
                if (this.configuracion.tipo_web == 1) {
                  await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                    this.products = resPrice;
                    // console.log("seleccionado", this.products);
                  });
                }
                if (this.configuracion.tipo_web == 2) {
                  await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                    await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                      this.products = resTalla;
                      // await this.webService.deleteProductsDuplicated(resTalla, 'tagDeGrupo').then(async (resProd) => {
                      //   this.products = resProd;
                      //   // console.log("seleccionado", this.products);
                      // });
                    });
                  });
                }
              } else {
                this.toaster.warning('No se ha encontrado productos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            });
          } else {
            await this.webService.getGruposService(url, 'filter').then(async (resCategorias: any) => {
              // console.log('Antes de la primera',resCategorias );
              
              if (!resCategorias.error) {
                if (resCategorias.rta == true) {
                  await this.webService.orderObjectsAsc(resCategorias.data).then(async (resorderg) => {
                    resCategorias.data = resorderg;
                    // console.log('Primera ==> ',resCategorias );
                    
                  });
                  await this.getSubgrupos(resCategorias.data, url).then(async (resSubgrup: any) => {
                    // console.log('subgrupos ===>' , resSubgrup );
                    
                    await this.webService.orderObjectsAsc(resSubgrup).then(async (resorder) => {
                      await this.webService.getProductosService(url, resorder[0].idgrupo, resorder[0].subgrupos[0].id_sub, this.configuracion).then(async (resprod: any) => {
                        // console.log('SUVB ', resprod);
                        
                        if (resprod.rta == true) {
                          if (this.configuracion.tipo_web == 1) {
                            await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                              this.products = resPrice;
                              // console.log("seleccionado", this.products);
                            });
                          }
                          if (this.configuracion.tipo_web == 2) {
                            await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                              await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                                this.products = resTalla;
                                // await this.webService.deleteProductsDuplicated(resTalla, 'tagDeGrupo').then(async (resProd) => {
                                //   this.products = resProd;
                                //   // console.log("seleccionado", this.products);
                                // });
                              });
                            });
                          }
                        } else {
                          this.toaster.warning('No se ha encontrado productos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                        }
                      });
                      await this.webService.saveTemporaryCatalogue(resorder);
                    });
                  });
                } else {
                  this.toaster.warning('Catálogo de la tienda vacio', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                }
              } else {
                this.toaster.error('Error al obtener las categorias', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            });
          }
        });
      }
      await this.webService.goUpPage();
      this.loadingAll = false;
    });
    await this.webService.observableFilterProducts().subscribe(async (res) => {
      this.loadingAll = true;
      await this.webService.orderProductPriceAscDesc(this.products, res).then((resorder) => {
        this.products = resorder;
      });
      this.loadingAll = false;
    });
    await this.webService.getProductPopularSoldService(url, this.configuracion).then(async (respopular: any) => {
      if (respopular.rta == true) {
        await this.webService.obtainAndCalculatePriceProduct(respopular.data, this.configuracion, this.login).then(async (resprice) => {
          this.productsSold = resprice;
        });
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  async showProducts() {
    await this.webService.observableProducts().subscribe(async (resprod) => {
      this.products = resprod;
      console.log("suscrito", this.products);
    });
  }

  async getConfiguracion() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        this.configuracion = data[0];
        // console.log(this.configuracion);

     
        
        

        // Mostrar todo el catalogo de productos
        // document.getElementById("aside-catalogo").style.height = "100%";
        await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then((resauth: any) => {
          if (resauth.rta == true) {
            this.configurationVariables.mostrar_precio = 1;
          } else {
            this.configurationVariables.mostrar_precio = this.configuracion.mostrar_precio;
          }
        });
        this.configurationVariables.show_attributes_prod = this.webService.showAtttibutesProducts(this.configuracion);
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async getSubgrupos(grupos, url_billing) {
    this.loadingAll = true;
    for (let g of grupos) {
      await this.webService.getSubgruposService(url_billing, g.idgrupo).then(async (resSubgrup: any) => {
        await this.webService.orderObjectsAsc(resSubgrup.data).then(async (resorder) => {
          g.subgrupos = resorder;
        });
      });
    }
    this.loadingAll = false;
    return grupos;
  }

  async modalViewDetailProduct(product) {
    // Informacion envida al modal
    let send = {
      product: product,
      configuracion: this.configuracion,
      show_price: this.configurationVariables.mostrar_precio,
      show_attributes_product: this.configurationVariables.show_attributes_prod,
      login: this.login,
      productsSold: this.productsSold
    }
    this.webService.setProductSelectedDetail(send);
    this.modalCtrl.open(DetailProductComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'xl' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      console.log("Close", result);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log("Dismiss", reason);
      this.webService.setProductSelectedDetail({});
    });
  }

  async loginClient() {
    this.modalCtrl.open(LoginUserComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
  }

  // Aqui hacerle que vea si abre el modal detalle producto o agrega directo al carrito
  // ***** ******
  public setMethodAddCart(product) {
    if (product.talla || product.guarnicion == true) {
      this.modalViewDetailProduct(product);
    } else {
      this.verifyLoginClient(product, 'direct');
    }
  }

  // De aqui siempre se va agregar el producto al carrito
  async verifyLoginClient(product, type) {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
      if (login.rta == true) {
        this.client = login.data;
        if (type == 'direct') {
          product.quantity = 1;
        }
        await this.addProductShoppingCart(product, login.data);
      } else {
        await this.loginClient();
      }
    });
  }

  async addProductShoppingCart(product, client) {
    if (product.precioReal > 0) {
      this.loadingAll = true;
      await this.webService.addProductCart(product, client).then(async (res: any) => {
        if (res.rta == true) {
          this.toaster.success(res.mensaje, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.warning(res.mensaje, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
      this.loadingAll = false;
    } else {
      this.toaster.warning('No se puede agregar el producto, precio no válido', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async sharedFacebook(product) {
    let url = this.configuracion.dominioPagina + '/product/selectProduct/' + product.id_producto + '/' + 0;
    // let url = 'http://www.localhost:4200/product/selectProduct/' + product.id_producto + '/' + 0;
    let shared = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
    window.open(shared, 'sharer', '_blank');
  }

}
