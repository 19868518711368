<app-submenu></app-submenu>

<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>

<section class="container-fluid">
    <div class="row pt-2 pb-2 pl-3 pr-3 ">
        <!-- Catalogo -->
        <div class="col-lg-3 order-lg-1 order-2 text-center mt-2" id="catalogo-products">
            <app-catalogo></app-catalogo>
        </div>
        <!-- Menu y Productos -->
        <div class="col-lg-9 order-lg-2 order-1">
            <app-menu></app-menu>
          
            <div class="row text-center w-100">
                <div class=" col-lg-6 col-md-6 col-12 mt-3" *ngFor="let p of products; let i=index">

                    <div class="card  box p-1">
                        <div class="card-body " >
                            <div class="row">
                                <div class="col-5  divSelect" type="button" (click)="modalViewDetailProduct(p)">
                              
                                        <img class=" " src="https://drive.google.com/uc?export=view&id={{p.imagenPrincipal}}"  alt="" style="width: 100%; height: auto; object-fit: scale-down;  border-radius: 7px; ">
                                </div>
                                <div class="col-7" >
                                    <div class="product-text" (click)="modalViewDetailProduct(p)">
                                      <!-- <h2>{{p.marca_nombre}}</h2> -->
                                      <p style="font-size: 0.8rem;">{{p.pro_nom |lowercase}} </p>
                                    </div>
                                    <p class="text-size-11  mb-1 text-dorado divSelect" >
                                      <i class="fas fa-star"></i>
                                      <i class="fas fa-star"></i>
                                      <i class="fas fa-star"></i>
                                      <i class="fas fa-star"></i>
                                      <i class="fas fa-star"></i>
                                  </p>
        
                                  <div class="card-body text-minuscula pt-1 "  style="line-height: 1.5em;">
                                    <p class="text-size-14 text-center sin-espacios"
                                    (click)="modalViewDetailProduct(p)"
                                        *ngIf="configurationVariables.mostrar_precio">
                                        <span class="text-secondary mr-2 text-subrayado-gray"
                                            *ngIf="p.precioOferta > 0">${{p.precioOferta | number: '1.2'}} </span>
                                            <br>
                                        <span style="font-family:'Helvetica' ; font-weight: bold;font-size: 1.8em; "> $ {{p.precioReal | number: '1.2'}} </span>
                                    </p>
                                    <p (click)="modalViewDetailProduct(p)" class="fw-bold  font-family='Helvetica'" style="font-size: 0.75rem; color:#2478c1; text-align: center;">
                                      Disponible en stock {{p.stockactual}}
                                    </p>
                                    
                                    <p class="text-size-11 sin-espacios mt-1 pt-1 pb-1 bg-light rounded border border-warning text-center"
                                        *ngIf="p.combo_venta_volumen">
                                        <span *ngFor="let pric of p.precios">
                                            <strong *ngIf="pric.combo">
                                                {{pric.combo}}
                                                <br>
                                            </strong>
                                        </span>
                                    </p>
                                    <!-- <div class="divSelect">
                                        <button class="btn btn-sm btn-warning fw-bold  ">
                                            Agregar a  carrito
                                        </button>
                                    </div> -->
        
                                    <div class=" button-group btn-group-sm divSelect ">
                                        <button  style="padding: 0.35em 1.3em;" class="btn m-1 btn-warning rounded-pill text-dark fw-bold" id="btnAddCar" title="Agregar a carrito" (click)="setMethodAddCart(p)">
                                          Agregar a carrito
                                        </button>
                                        <button  style="padding: 0.35em 1.3em; display: none;" class="btn m-1 btn-warning rounded-pill text-dark fw-bold" id="btnAddCar2" title="Agregar a carrito" (click)="setMethodAddCart(p)">
                                            <i class="bi bi-cart4"></i>
                                          
                                        </button>
                                        <!-- <button style="opacity: .50;"  class="btn bg-dark text-white rounded-circle m-1" id="btnAddCar{{i}}" title="Agregar a carrito" (click)="setMethodAddCart(p)">
                                          <i class="bi bi-cart4"></i>
                                        </button> -->
                                        <!-- <button   style="opacity: .50;" class="btn btn-warning rounded-circle m-1" id="btnVer{{i}}" title="Ver detalle producto" (click)="sharedFacebook(p)" >
                                          <i class="bi bi-share-fill"></i>
                                        </button> -->
                            
                                      </div>
                                </div>
        
        
        
        
                                    
                                </div>
                            </div>
                     
                       
                         
                        </div>
                      </div>


                    <!-- <div class="card shadow p-3 mb-3 bg-white rounded div-product animate__animated animate__zoomIn">

                        <div class="w-100 text-center" (click)="modalViewDetailProduct(p)">
                            <img class="img-productos"
                                src="https://drive.google.com/uc?export=view&id={{p.imagenPrincipal}}" alt="">
                            <p class="text-size-11 mt-2 mb-1 text-dorado">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </p>
                        </div>

                        <div class="card-body text-minuscula pt-1 mb-1 height-125 p-0">
                            <p class="text-size-14 text-center sin-espacios"
                                *ngIf="configurationVariables.mostrar_precio">
                                <span class="text-secondary mr-2 text-subrayado-gray" *ngIf="p.precioOferta > 0">$
                                    {{p.precioOferta | number: '1.2'}} </span>
                                <span> $ {{p.precioReal | number: '1.2'}} </span>
                            </p>
                            <hr>
                            <p class="text-size-12 text-center pl-0 pr-0 sin-espacios">
                                {{p.pro_nom}}
                            </p>
                            <p class="text-size-11 sin-espacios mt-1 pt-1 pb-1 bg-light rounded border border-warning" *ngIf="p.combo_venta_volumen">
                                <span *ngFor="let pric of p.precios">
                                    <strong *ngIf="pric.combo" class="text-center">
                                        {{pric.combo}}
                                        <br>
                                    </strong>
                                </span>
                            </p>
                        </div>

                        <br>
                        <div class="row text-center border-top p-1" style="position: absolute; width: 100%; bottom: 0;">
                            <div class="col-4 text-right div-icn-prod" (click)="setMethodAddCart(p)">
                                <i class="fas fa-cart-plus"></i>
                            </div>
                            <div class="col-4 text-center div-icn-prod" (click)="modalViewDetailProduct(p)">
                                <i class="fas fa-eye"></i>
                            </div>
                            <div class="col-4 text-left div-icn-prod" (click)="sharedFacebook(p)">
                                <i class="fas fa-share-alt"></i>
                            </div>
                        </div>

                        <br>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="container-fluid">
    <!-- <app-menu-responsivo></app-menu-responsivo> -->
</section>

<app-scroll-to-top></app-scroll-to-top>

<app-footer></app-footer>
