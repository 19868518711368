import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ServicesService } from '../../../shared/services/services.service';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit {

  public closeResult: string;
  public loadingAll = false;
  public configuracion;
  public login = {
    usuario: '',
    clave: '',
    viewPass: true
  }

  constructor(
    private modalCtrl: NgbModal,
    private webService: ServicesService,
    private toaster: ToastrService
  ) { }

  async ngOnInit() {
    this.loadingAll = true;
    await this.getConfiguracion();
    this.loadingAll = false;
  }

  async getConfiguracion() {
    await this.webService.getConfiguracion().then(async (dataConf: any) => {
      this.configuracion = dataConf[0];

      let bl = document.getElementById('btn-login');
      bl.style.background = this.configuracion.colorPrincipal;
      bl.style.color = this.configuracion.colorLetra;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  async dismissModal(data) {
    this.modalCtrl.dismissAll(data);
    if (data == 'Administrator') {
      if (this.login.usuario && this.login.clave) {
        await this.loginAdministrator(this.login, this.configuracion);
      } else {
        this.toaster.warning('Ingrese sus credenciales', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    }
  }

  async loginAdministrator(login, configuracion) {
    this.toaster.info('Espere un momento ...', '', { timeOut: 1000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    await this.webService.loginAdministrator(login).then(async (resadmin: any) => {
      if (resadmin.rta == true) {
        resadmin.data.PersonaComercio_cedulaRuc = resadmin.data.cedula;
        resadmin.data.default_price = configuracion.tipoPrecio;
        await this.webService.saveUserLocalStorage(resadmin.data, configuracion.loginStorage, "Administrator").then(async (resLocal: any) => {
          await this.webService.getproductsCart({ id_cliente: resadmin.data.cedula }).then(async (resprod: any) => {
            if (resprod.rta == true) {
              let observable = {
                number: resprod.data.length,
                total: await this.webService.calculateTotalCartProducts(resprod.data)
              }
              this.webService.shopcart$.next(observable);
            }
          });
          // await this.webService.refreshPage(configuracion);
          this.toaster.success('Bienvenid@, ' + resadmin.data.nombres, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          await this.webService.goAdminProfile();
        });
      } else {
        this.toaster.warning('Usuario no encontrado, Registrelo.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        this.dismissModal('Registrar');
      }
    });
  }

}
