import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';

import { HomeComponent } from '../app/pages/home/home.component';
import { CarritoComponent } from '../app/pages/carrito/carrito.component';
import { LoginAdminComponent } from '../app/pages/login/login-admin/login-admin.component';
// import { LoginUserComponent } from '../app/pages/login/login-user/login-user.component';
import { ProductsComponent } from '../app/pages/products/products.component';
import { AdminComponent } from '../app/pages/profile/admin/admin.component';
import { UserComponent } from '../app/pages/profile/user/user.component';
import { AboutUsComponent } from '../app/pages/about-us/about-us.component';
import { PoliticsAndTermsComponent } from '../app/pages/politics-and-terms/politics-and-terms.component';
import { VaucherComponent } from '../app/pages/vaucher/vaucher.component';

import { GuardsGuard } from '../app/collaborator/guards/guards.guard';
import { ServicesService } from '../app/shared/services/services.service';
import { InicioComponent } from './pages/inicio/inicio.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home/:value',
    component: HomeComponent
  },
  {
    path: 'carrito',
    component: CarritoComponent, canActivate: [GuardsGuard]
  },
  {
    path: 'admin',
    component: LoginAdminComponent, canActivate: [GuardsGuard]
  },
  {
    path: 'products',
    component: ProductsComponent
  },
  {
    path: 'product/:type/:value/:value2',
    component: ProductsComponent
  },
  {
    path: 'administrator',
    component: AdminComponent, canActivate: [GuardsGuard]
  },
  {
    path: 'user',
    component: UserComponent, canActivate: [GuardsGuard]
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'politics',
    component: PoliticsAndTermsComponent,
  },
  {
    path: 'inicio',
    component: InicioComponent,
  },
  {
  //   path: 'extras',
  //   loadChildren:()=>import('./componentes/componentes.module').then(m=>m.ComponentesModule)
  // }
    // path: 'vaucher/:id',
    path: 'vaucher',
    component: VaucherComponent,
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {

  constructor(
    private webService: ServicesService,
  ) {

    // console.log(routes);
    routes[0].component = AboutUsComponent;
    // console.log(routes);
  }
}
