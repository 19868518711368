<br>
<body class="d-flex flex-column">
    <!-- For demo purpose -->
    <!-- <div class="container-fluid flex-grow-1 flex-shrink-0">
      <div class="px-lg-5">
        <div class="row py-5">
          <div class="col-lg-12 mx-auto text-white text-center">
            <h1 class="display-4">Bootstrap 4 footer</h1>
            <p class="lead mb-0">Build a nicely styled light footer using Bootstrap 4.</p>
            <p class="lead">Snippet by <a href="https://bootstrapious.com/snippets" class="text-white">
                          <u>Bootstrapious</u></a>
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- End -->
  
  
    <!-- Footer -->
    <footer class="" style="background-color:#EDEDED">
      <div class="container py-5">
        <div class="row py-4">
          <div class="col-lg-4 col-md-6 mb-4 mb-lg-0" style="line-height: 1.5em;">               
          <img class="logo" src="https://drive.google.com/uc?export=view&id={{configuracion?.imgLogo}}" alt="" width="181px" height="80px">
          <!-- <img class="logo" src="https://drive.google.com/uc?export=view&id={{configuracion?.imgFooter}}" alt="" width="181px" height="80px"> -->

            <p class="text-muted"><strong class=""> Dirección:</strong>  {{informacion?.direccion}} </p>
            <p class="text-muted"><strong class=""> Email:</strong>  {{informacion?.email}} </p>
            <p class="text-muted"><strong class=""> Teléfono:</strong>  {{informacion?.telefonos}} </p>

          </div>
          <div class="col-lg-2 col-md-6 col-6 mb-4 mb-lg-0">
            <h6 class="text-uppercase font-weight-bold mb-4">PÁGINAS</h6>
            <ul class="list-unstyled mb-0">
              <li class="mb-2"><a  class="nav-link active" [routerLink]="[ '/inicio' ]" routerLinkActive="active" class="text-muted">Inicio</a></li>
              <li class="mb-2"><a type="button" (click)="goHome()" class="text-muted">E-commerce</a></li>
              <li class="mb-2"><a type="button" (click)="goProductsCatalogue()"  class="text-muted">Productos</a></li>
              <li class="mb-2"><a href="#" class="text-muted"  type="button" (click)="goHome()">Sobre Nosotros</a></li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-6 col-6 mb-4 mb-lg-0">
            <h6 class="text-uppercase font-weight-bold mb-4">CONTACTO</h6>
            <ul class="list-unstyled mb-0">
              <li class="mb-2"><a (click)="goToSocialNetwork(informacion?.facebook)" target="_blank" title="facebook" class="text-dark " ><i class="fa fa-facebook" id="iFB"> </i> Facebook </a></li>
              <li class="mb-2"><a (click)="goToSocialNetwork(informacion?.instagram)" target="_blank" title="Instagram" class="text-dark "><i class="fa fa-instagram" id="iIG">  </i> Instagram</a></li>
              <li class="mb-2"><a (click)="goToSocialNetwork(informacion?.youtube)" target="_blank" title="Youtube" class="text-dark "><i class="fa fa-youtube" id="iYT">  </i> Youtube</a> </li>
              <li class="mb-2"><a (click)="goToSocialNetwork(informacion?.tiktok)" target="_blank" title="TikTok" class="text-dark "><i class="fa fa-tiktok" id="iTT"> </i> TikTok</a></li>
              <li class="mb-2"><a (click)="goToSocialNetwork(informacion?.twitter)" target="_blank" title="twitter" class="text-dark "><i class="fa fa-twitter" id="iTW"> </i> Twitter</a> </li>

            </ul>
          </div>
          <div class="col-lg-4 col-md-6 mb-lg-0">
            <h6 class="text-uppercase font-weight-bold mb-4">Suscríbete</h6>
            <p class="text-muted mb-4">Suscribase a nuestra lista de noticias y manténganse siempre actualizado</p>
            <div class="p-1 rounded border">
              <div class="input-group">
                <input type="email" placeholder="Ingrese su correo electrónico" aria-describedby="button-addon1" class="form-control border-0 shadow-0">
                <div class="input-group-append">
                  <button id="button-addon1" type="submit" class="btn btn-link rounded-pill"><i class="fa fa-paper-plane" id="iSubs"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Copyrights -->
      <div class="py-2" id="divFooter">
        <div class="container text-center" type="button" (click)="goToSocialNetwork('https://www.sofpymes.com')">
          <p class=" mb-0 py-2">{{footerText}}</p>
        </div>
      </div>
    </footer>
    <!-- End -->
  
  </body>
<!-- <section class="container-fluid pt-4 pb-4" id="div-footer">
    <div class="row text-minuscula text-size-14 pb-3 div-content">
        <div class="col-lg-9 pt-2 pb-2">
            <div class="row text-center">
                <div class="col-lg-4">
                    <img class="logo" src="https://drive.google.com/uc?export=view&id={{configuracion?.imgLogo}}" alt="" width="181px" height="80px">
                    <hr>
                    <div class="text-justify ml-3 mt-3 text-minuscula-texto text-blanco">

                        <div class="row">
                            <div class="col-2 pl-0 pr-0 text-right">
                                <i class="fas fa-map-marker-alt text-size-17 maps" id="icn-direccion"></i>
                            </div>
                            <div class="col-10">
                                <strong>
                                    {{informacion?.direccion}}
                                </strong>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-2 pl-0 pr-0 text-right">
                                <i class="fas fa-phone-alt text-size-17 telefono" id="icn-telefono"></i>
                            </div>
                            <div class="col-10 pr-2">
                                <strong>{{informacion?.telefonos}}</strong>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-2 pl-0 pr-0 text-right">
                                <i class="fas fa-envelope text-size-17 gmail" id="icn-email"></i>
                            </div>
                            <div class="col-10 pr-2">
                                <strong>{{informacion?.email}}</strong>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-2 pl-0 pr-0 text-right">
                                <i class="fab fa-whatsapp text-size-17 whatsapp" id="icn-whats"></i>
                            </div>
                            <div class="col-10 pr-2">
                                <strong>{{informacion?.whatsapp}}</strong>
                            </div>
                        </div>


                    </div>

                </div>

                <div class="col-lg-4 pl-5 pr-5">
                    <span class="text-mayuscula-titulos-normal text-size-18" id="tit-redes"> NUESTRA EMPRESA </span>
                    <hr color="white">
                    <p class="text-left pl-5 text-minuscula-texto text-blanco tipo-cursor" (click)="goAboutUs()">
                        <span class="list-info">
                            <i class="fas fa-landmark"></i>
                            <span class="ml-3">Historia</span>
                        </span>
                    </p>
                    <p class="text-left pl-5 text-minuscula-texto text-blanco tipo-cursor" (click)="goAboutUs()">
                        <span class="list-info">
                            <i class="fas fa-rocket"></i>
                            <span class="ml-3">Misión</span>
                        </span>
                    </p>
                    <p class="text-left pl-5 text-minuscula-texto text-blanco tipo-cursor" (click)="goAboutUs()">
                        <span class="list-info">
                            <i class="fas fa-binoculars"></i>
                            <span class="ml-3">Visión</span>
                        </span>
                    </p>
                    <p class="text-left pl-5 text-minuscula-texto text-blanco tipo-cursor" (click)="goAboutUs()">
                        <span class="list-info">
                            <i class="fas fa-hand-holding-heart"></i>
                            <span class="ml-3">Valores</span>
                        </span>
                    </p>
                    <p class="text-left pl-5 text-minuscula-texto text-blanco tipo-cursor" *ngIf="configuracion.terminosCondiciones == 1" (click)="goPoliticsAndTerms()">
                        <span class="list-info">
                            <i class="fas fa-clipboard-list"></i>
                            <span class="ml-3">Términos y Condiciones</span>
                        </span>
                    </p>
                </div>

                <div class="col-lg-4 pl-5 pr-5">

                    <span class="text-mayuscula-titulos-normal text-size-18" id="tit-empresa"> REDES SOCIALES </span>
                    <hr color="white">
                    <p class="text-left pl-5 text-minuscula-texto text-blanco tipo-cursor">
                        <span class="list-info" (click)="goToSocialNetwork(informacion?.facebook)">
                            <i class="fab fa-facebook-square text-size-19 facebook"></i>
                            <span class="ml-3">Facebook</span>
                        </span>
                    </p>
                    <p class="text-left pl-5 text-minuscula-texto text-blanco tipo-cursor">
                        <span class="list-info" (click)="goToSocialNetwork(informacion?.youtube)">
                            <i class="fab fa-youtube text-size-19 youtube youtube"></i>
                            <span class="ml-3">Youtube</span>
                        </span>
                    </p>
                    <p class="text-left pl-5 text-minuscula-texto text-blanco tipo-cursor">
                        <span class="list-info" (click)="goToSocialNetwork(informacion?.instagram)">
                            <i class="fab fa-instagram-square text-size-19 instagram"></i>
                            <span class="ml-3">Instagram</span>
                        </span>
                    </p>
                    <p class="text-left pl-5 text-minuscula-texto text-blanco tipo-cursor">
                        <span class="list-info" (click)="goToSocialNetwork(informacion?.twitter)">
                            <i class="fab fa-twitter text-size-19 twitter"></i>
                            <span class="ml-3">Twitter</span>
                        </span>
                    </p>
                    <p class="text-left pl-5 text-minuscula-texto text-blanco tipo-cursor">
                        <span class="list-info" (click)="goToSocialNetwork(informacion?.tiktok)">
                            <i class="fab fa-tiktok text-size-19 tiktok"></i>
                            <span class="ml-3">Tik Tok</span>
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <div class="col-lg-3 mt-3 mb-3 text-center d-flex">
            <youtube-player [videoId]="videoYoutube" width="100%" height="auto"></youtube-player>
        </div>
    </div>

    <div class="row text-minuscula text-size-14 div-content">
        <div class="col-lg-12 text-center tipo-cursor" id="div-rights" (click)="goToSocialNetwork('https://www.sofpymes.com')">
            {{footerText}}
            <i class="fas fa-mouse ml-2"></i>
        </div>
    </div>
</section> -->
