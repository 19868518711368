import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// Drive
import { AppContext } from '../../../../infrastructure/app.context';
import { FileInfo } from '../../../../models/fileInfo';


import { ServicesService } from '../../../shared/services/services.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  @ViewChild('section-pdf') content: ElementRef;

  public closeResult: string;
  public loading = false;
  public configuracion: any = {};
  public menu_client: any = {};
  public information: any = {};
  public pricesTypes: any = {};
  public bodegas: any = {};
  public promotions: any = {};
  public imagesBanner = [];
  public groups = [];
  public groupsFree = [];
  public groupSelected: any = {};
  public products = [];
  public searchProd = '';
  public searchGroup = '';
  public colorsTable: any = [];
  public productSelected: any = {};
  public searchProductsReferidos: any = {};
  public productReferidos: any = [];
  public provinces: any = [];
  public guiaTallasAll: any = [];
  public guiaTallas: any = {};
  public guiaTallasDetalle: any = {};
  public newGuiaTalla: any = {};
  public name_guia = '';
  public menu = {
    pedidos: false,
    datosGenerales: true,
    sobreNosotros: false,
    descuentos: false,
    imagenesBanner: false,
    imagenesGrupos: false,
    imagenesProductos: false,
    coloresProductos: false,
    guiaTallas: false,
    credenciales: false,
    promociones: false,
    pixelFacebook: false,
    generarCatalogo: false,
    vaucher: false
  }
  public price = {
    before: 50,
    now: 0
  }
  public administration: {
    data: any,
    validate: {
      current: '',
      new: ''
    }
  };
  public newPromotion: any = {};
  public cotizations: any = [];
  public cotizationsSelected: any = {};
  public queryCortizations = {
    cedula: '',
    desde: '',
    hasta: '',
  }
  public vaucher: any = {
    id_empresa: null,
    valor_transaccion: null,
    detalle_transaccion: null,
    fecha_creacion: null,
    hora_creacion: null,
    estado: 0
  };
  public myAngularxQrCode: string = null;

  public catalogue = {
    id_grupo: 0,
    name_grupo: '',
    id_subgrupo: 0,
    name_subgrupo: '',
    complete_inventory: false,
    type: 'pA',
    subGrupos: [],
    products: [],
    products_all: [],
    view_products: false,
    search: '',
    stock: true,
    description: true,
    peso: true,
    medida: true,
    ubicacion: true,
    origen: true,
    origen_arr: [
      {
        id: 0,
        nombre: ' * TODAS'
      },
      {
        id: 1,
        nombre: 'NACIONAL'
      },
      {
        id: 2,
        nombre: 'IMPORTADO'
      }
    ],
    origen_select: 0,
    cant_bulto: true,
    marca: true,
    marcas: [],
    marca_select: 0
  }
  public colorAddTable = {
    nombre: '',
    codigo: '',
    id_empresa: '',
    descripcion: null
  }
  public variables = {
    descripcion: '',
    fotourl: '',
    referido: ''
  }
  public buttonsPay: any = {};
  collapsed = false

  constructor(
    private appContext: AppContext,
    private webService: ServicesService,
    private toaster: ToastrService,
    private modalCtrl: NgbModal,
  ) { }

  async ngOnInit() {

    if (!this.appContext.Session.Gapi.isSignedIn) {
      await this.signInGoogle();
    }
    this.loading = true;
    await this.getConfiguration();
    await this.verifyLoginClient();
    await this.getInformation();
    await this.getPricesTypes();
    await this.getBodegasAll();
    await this.getImagesBanner();
    await this.getAtributesProducts();
    // await this.getGroups();
    await this.getPromotionsWeb();
    await this.webService.visibilityPurchaseButtons(this.configuracion, {}).then((resbtn: any) =>{
      this.buttonsPay = resbtn;
    });
    this.loading = false;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async signInGoogle() {
    this.appContext.Session.Gapi.signIn().then((res) => {
      console.log("Logeo google");
    })
  }

  toggle() {
    this.collapsed = !this.collapsed;
  }

  async goHome() {
    await this.webService.goHomeClean();
  }

  async signOff() {
    await this.webService.signOuth(this.configuracion.loginStorage).then(async (resClose: any) => {
      this.goHome();
    });
  }

  async getConfiguration() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        if (data[0]) {
          this.configuracion = data[0];
          // Precio oferta y real ejemplo
          if (this.configuracion.porcentajePrecioOferta > 0) {
            let porcent = (this.price.before * this.configuracion.porcentajePrecioOferta) / 100;
            this.price = {
              before: 50,
              now: this.price.before - porcent
            }
          }
          // console.log(this.configuracion);
        } else {
          console.log("No se ha encontrado configuracion");
        }
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async verifyLoginClient() {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
      if (login.rta == true) {
        if (login.data.rol != 'Administrator') {
          this.webService.goUserProfile();
        }
      } else {
        this.webService.goHome();
      }
    });
  }

  async getInformation() {
    await this.webService.getInformacion().then(async (data: any) => {
      if (!data.error) {
        if (data[0]) {
          this.information = data[0];
          // console.log("Informacion", this.information);
        } else {
          console.log("No se ha encontrado information");
        }
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async selectMenu(type) {
    // Cerrar menu si la pantalla es muy pequeña
    if (screen.width < 560) {
      this.collapsed = true;
    }

    this.menu = {
      pedidos: false,
      datosGenerales: false,
      sobreNosotros: false,
      descuentos: false,
      imagenesBanner: false,
      imagenesGrupos: false,
      imagenesProductos: false,
      coloresProductos: false,
      guiaTallas: false,
      credenciales: false,
      promociones: false,
      pixelFacebook: false,
      generarCatalogo: false,
      vaucher: false

    }
    if (type == "pedidos") {
      this.menu.pedidos = true;
      await this.listActualCotizations();
    }
    if (type == "datosGenerales") {
      this.menu.datosGenerales = true;
    }
    if (type == "sobreNosotros") {
      this.menu.sobreNosotros = true;
    }
    if (type == "descuentos") {
      await this.getProvinces();
      await this.getOpcionMenu();
      this.menu.descuentos = true;
    }
    if (type == "imagenesBanner") {
      this.menu.imagenesBanner = true;
    }
    if (type == "imagenesGrupos") {
      await this.getGroupFree();
      this.menu.imagenesGrupos = true;
    }
    if (type == "imagenesProductos") {
      await this.getGroups();
      this.menu.imagenesProductos = true;
    }
    if (type == "coloresProductos") {
      this.menu.coloresProductos = true;
      await this.getColorsTable();
    }
    if (type == "guiaTallas") {
      this.menu.guiaTallas = true;
      await this.getGuiaTallasAll();
    }
    if (type == "promociones") {
      await this.getGroups();
      this.menu.promociones = true;
    }
    if (type == "catalogue") {
      await this.getGroups();
      await this.getPricesTypes();
      // await this.getAtributesProducts();
      // if (this.groups.length > 0) {
      //   await this.viewDataCatalogue(this.groups[0].idgrupo, 'pA');
      // }
      this.menu.generarCatalogo = true;
    }
    if (type == "credenciales") {
      await this.getDataAdministration();
      this.menu.credenciales = true;
    }
    if (type == "pixelFacebook") {
      this.menu.pixelFacebook = true;
    }
    if (type == "vaucher") {
      this.menu.vaucher = true;

      // await this.webService.getCurrentDate().then(async (date: any) => {
      //   this.vaucher.message = date.day_name + ', ' + date.day_number + ' de ' + date.month_name + ' del ' + date.year;
      // });
    }
  }

  async updateColors(colorFondo, colorTexto) {
    if (colorFondo || colorTexto) {
      this.configuracion.colorPrincipal = colorFondo;
      this.configuracion.colorLetra = colorTexto;
      this.loading = true;
      await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
        this.loading = false;
        await this.getConfiguration();
        this.toaster.success('Colores actualizados exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      });
    } else {
      this.toaster.error('Seleccione un color', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }

  }

  async ModalUploadFile(uploadFileModal, type, other) {
    this.modalCtrl.open(uploadFileModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      await this.uploadFileDrive(result, type, other);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // Funcion general para subir imagen al drive
  async uploadFileDrive(archive, type, other) {

    let drive;
    const file: File = archive.files[0];

    if (file) {

      this.loading = true;

      if (type == 'Logo') {
        drive = this.configuracion.urlLogo;
      }

      if (type == 'AboutUs') {
        drive = this.configuracion.urlLogo;
      }

      if (type == 'Banner') {
        drive = this.configuracion.urlBanner;
      }

      if (type == 'Groups') {
        drive = this.configuracion.urlLogo;
      }

      if (type == 'Subgroups') {
        drive = this.configuracion.urlLogo;
      }

      if (type == 'Img_1' || type == 'Img_2' || type == 'Img_3' || type == 'Img_4') {
        drive = this.configuracion.urlProductos;
      }

      if (type == 'Promotion') {
        drive = this.configuracion.urlPromocion;
      }

      if (type == 'Footer') {
        drive = this.configuracion.urlLogo;
      }

      const reader = new FileReader();
      let fileInfo = new FileInfo();
      fileInfo.Name = file.name;
      fileInfo.Blob = file;

      if (this.appContext.Session.Gapi.isSignedIn == true) {
        await this.appContext.Repository.File.importFile(drive, fileInfo,
          (res) => this.onImportError(res),
          (res) => this.onCompleteUpload(res, type, other),
          (res) => this.onImportProgress(res)
        )
      } else {
        this.loading = false;
        await this.toaster.warning('Para poder cargar imagenes debe Iniciar Sesión con su cuenta de Gmail', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        // await this.signInGoogle();
      }
    } else {
      this.toaster.error('Seleccione una imagen, por favor.', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async onCompleteUpload(res, type, other) {

    // console.log("type", type);
    if (type == 'Logo') {
      await this.updateImageLogo(res);
    }

    if (type == 'AboutUs') {
      await this.createUpdateAboutUs(res, type, other);
    }

    if (type == 'Banner') {
      await this.insertImagenBanner(res);
    }

    if (type == 'Groups') {
      await this.updateImageGroup(res, type, other);
    }

    if (type == 'Subgroups') {
      await this.updateImageSubgroup(res, type, other);
    }

    if (type == 'Img_1') {
      let img = 'imagen_uno';
      await this.updateImageProduct(res, type, img, other)
    }
    if (type == 'Img_2') {
      let img = 'imagen_dos';
      await this.updateImageProduct(res, type, img, other)
    }
    if (type == 'Img_3') {
      let img = 'imagen_tres';
      await this.updateImageProduct(res, type, img, other)
    }
    if (type == 'Img_4') {
      let img = 'imagen_cuatro';
      await this.updateImageProduct(res, type, img, other)
    }

    if (type == 'Promotion') {
      await this.insertPromotionWeb(res, other);
    }

    if (type == 'Footer') {
      await this.updateImageFooter(res);
    }
    this.loading = false;
  }

  onImportError(res) {
    this.loading = false;
    console.log("error", res)
    this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  };

  onImportProgress(res) {
    // console.log("proceso", res)
  };

  async updateImageLogo(res) {
    let data = JSON.parse(res);
    let urlImgAnt = this.configuracion.imgLogo;
    this.configuracion.imgLogo = data.id;
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      await this.getConfiguration();
      this.toaster.success('La Imagen ha sido actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      if (urlImgAnt) {
        this.appContext.Repository.File.delete(urlImgAnt).then(async (resdrive) => { });
      }
    });
  }

  async updateInformation(information) {
    this.loading = true;
    await this.webService.updateInformation(information).then(async (data) => {
      await this.getInformation();
      this.toaster.success('Información actualizada exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
    this.loading = false;
  }

  async createUpdateAboutUs(res, type, other) {
    this.loading = true;
    let data = JSON.parse(res);
    let imageDelet;

    if (other == 'history') {
      imageDelet = this.information.imagenHistoria;
      this.information.imagenHistoria = data.id;
      await this.updateAboutUs(this.information, imageDelet);
    }

    if (other == 'mision') {
      imageDelet = this.information.imagenMision;
      this.information.imagenMision = data.id;
      await this.updateAboutUs(this.information, imageDelet);
    }

    if (other == 'vision') {
      imageDelet = this.information.imagenVision;
      this.information.imagenVision = data.id;
      await this.updateAboutUs(this.information, imageDelet);
    }

    if (other == 'valores') {
      imageDelet = this.information.imagenValores;
      this.information.imagenValores = data.id;
      await this.updateAboutUs(this.information, imageDelet);
    }

    this.loading = false;
  }

  async updateAboutUs(information, imageDelet) {
    await this.updateInformation(information);
    if (imageDelet) {
      this.appContext.Repository.File.delete(imageDelet).then(async (resDrive) => {
      });
    }
  }

  async getPricesTypes() {
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getPricesType(url).then(async (resprices: any) => {
        this.pricesTypes = resprices;
      });
    });
  }

  async updatePriceType(p) {
    this.loading = true;
    this.configuracion.tipoPrecio = p.target.value;
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      this.loading = false;
      await this.getConfiguration();
      this.toaster.success('Tipo de precio actualizado exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
  }

  async updateShowPrice(p) {
    this.loading = true;
    this.configuracion.mostrar_precio = p.target.value;
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      this.loading = false;
      await this.getConfiguration();
      this.toaster.success('Mostrar precio actualizado exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
  }

  async getBodegasAll() {
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getBodegasAll(url).then((resbodega: any) => {
        if (resbodega.rta == true) {
          this.bodegas = resbodega.data;
        }
      });
    });
  }

  async updateBodega(b) {
    this.loading = true;
    this.configuracion.id_bodega = b.target.value;
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      this.loading = false;
      await this.getConfiguration();
      this.toaster.success('Bodega actualizada exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
  }

  async updateTrendProductsShow(t) {
    this.loading = true;
    this.configuracion.productos_tendencia = t.target.value;
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      this.loading = false;
      await this.getConfiguration();
      this.toaster.success('Productos de tendencia actualizado exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
  }

  async updateApplyShippingCost(e) {
    this.loading = true;
    this.configuracion.aplicarCostoEnvioBtn1 = e.target.value;
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      this.loading = false;
      await this.getConfiguration();
      this.toaster.success('Aplicar costo de envío actualizado exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
  }

  async updateShowButton(w, type) {
    this.loading = true;
    if (type == 'whatsapp') {
      this.configuracion.visibilidadBtnWhatsapp = w.target.value;
    }
    if (type == 'entrega') {
      this.configuracion.valor_minimo_compra = w.target.value;
    }
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      this.loading = false;
      await this.getConfiguration();
      this.toaster.success('Visualizar botón actualizado exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
  }

  async updateConfiguration(configuracion) {

    this.loading = true;

    if (!configuracion.porcentajePrecioOferta) {
      configuracion.porcentajePrecioOferta = 0;
    }
    if (!configuracion.porcentajeDescuento) {
      configuracion.porcentajeDescuento = 0;
    }
    if (!configuracion.valor_minimo_compra) {
      configuracion.valor_minimo_compra = 0;
    }
    if (!configuracion.porcentajeCompraTarjeta) {
      configuracion.porcentajeCompraTarjeta = 0;
    }
    if (!configuracion.costoEnvio) {
      configuracion.costoEnvio = 0;
    }
    if (!configuracion.costoEnvio2) {
      configuracion.costoEnvio2 = 0;
    }

    await this.webService.updateConfiguracion(configuracion).then(async (data) => {
      this.loading = false;
      await this.getConfiguration();
      this.toaster.success('Información actualizada exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });

  }

  async updateRouteDefault(e) {
    this.loading = true;
    this.configuracion.ruta_inicio_defecto = e.target.value;
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      this.loading = false;
      await this.getConfiguration();
      this.toaster.success('Ruta principal del sitio actualizada exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
  }

  async getImagesBanner() {
    await this.webService.getImagesBanner().then(async (data: any) => {
      if (!data.error) {
        this.imagesBanner = data;
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async insertImagenBanner(res) {
    let data = JSON.parse(res);
    let url = data.id;
    let datos = {
      nombre: data.name,
      url: url
    }
    this.webService.insertImageBanner(datos).then((data: any) => {
      console.log('resp guardar imagen', data);
      if (!data.error) {
        this.getImagesBanner();
        this.toaster.success('Banner insertado exitoramente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      } else {
        this.appContext.Repository.File.delete(url).then(async (resDrive) => { console.log(resDrive); });
        this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async deleteBanner(banner) {
    this.loading = true;
    await this.webService.deleteImageBanner(banner.id_img_banner).then((resdel: any) => {
      if (!resdel.error) {
        this.getImagesBanner();
        this.appContext.Repository.File.delete(banner.url).then(async (resDrive) => { });
      } else {
        this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
    this.loading = false;
  }

  async ModaldeleteFile(deleteFileModal, data, type) {
    this.modalCtrl.open(deleteFileModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      if (type == "Banner") {
        await this.deleteBanner(data);
      }
      if (type == 'Promotion') {
        await this.deletePromotionWeb(data);
      }
      if (type == 'Color') {
        await this.deleteColorTable(data);
      }
      if (type == 'talla_guia_detalle') {
        await this.deleteGuiaTallasDetalle(data);
      }
      if (type == 'talla_guia') {
        await this.deleteGuiaTallas(data);
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async getGroups() {
    // console.log("getGroups");
    let tipo_web = this.configuracion.tipo_web;
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      if (tipo_web == 1 || tipo_web == 2 || tipo_web == 4) {
        await this.webService.getGruposService(url, 'filter').then(async (resgrupos: any) => {
          if (!resgrupos.error) {
            if (resgrupos.rta == true) {
              await this.webService.orderObjectsAsc(resgrupos.data).then(async (resorderg) => {
                let aux = {
                  codigo: '0',
                  nombre: "* LISTAR TODOS",
                  url_billing: resorderg[0].url_billing,
                  img: "",
                  id_grupo: '0',
                }
                resorderg.push(aux);
                this.groups = resorderg;
                await this.selectGroup(this.groups[0].idgrupo);
                this.catalogue.id_grupo = this.groups[0].idgrupo;
                await this.getSubGroups(this.groups[0].idgrupo);
              });
            } else {
              this.toaster.warning('Catálogo de la tienda vacio', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          } else {
            this.toaster.error('Error al obtener las categorias', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      }
      if (tipo_web == 3) {
        console.log("Tienda con 2 BD, codigo se encuentra en proceso");
      }
    });
    this.loading = false;
  }

  async getSubGroups(idgrupo) {
    this.loading = true;
    this.restablishFilters('');
    this.catalogue.id_grupo = idgrupo;
    await this.webService.getUrlEmpresa().then(async (url) => {
      if (idgrupo == 0) {
        await this.getProductsAll();
      } else {
        await this.webService.getSubgruposService(url, idgrupo).then(async (ressub: any) => {
          if (!ressub.error) {
            if (ressub.rta == true) {
              await this.webService.orderObjectsAsc(ressub.data).then(async (resorderg) => {
                let aux = {
                  estado: 1,
                  id_grupo: resorderg[0].id_grupo,
                  id_sub: -1,
                  img: "",
                  nombre: "* LISTAR TODOS",
                  url_billing: resorderg[0].url_billing,
                }
                resorderg.push(aux);
                this.catalogue.subGrupos = resorderg;
                await this.getProductsGrupoSubgrupo(resorderg[0].id_sub);
                this.catalogue.name_grupo = this.getNameGrupoSubgrupo(idgrupo, 'grupo');
              });
            } else {
              this.toaster.warning('No posee sub categorias', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          } else {
            this.toaster.error('Error al obtener las sub categorias', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      }
    });
    this.loading = false;
  }

  async getGroupFree() {
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getGruposService(url, 'all').then(async (resgrupos: any) => {
        this.loading = false;
        if (!resgrupos.error) {
          if (resgrupos.rta == true) {
            for (let g of resgrupos.data) {
              g.vista_web = parseInt(g.vista_web);
              g.ocult = false;
            }
            this.groupsFree = await this.webService.orderObjectsAsc(resgrupos.data);
          } else {
            this.toaster.warning('No se ha encontrado categorias', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
          // console.log("getGroupFree", this.groupsFree);
        } else {
          this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });

    });
  }

  async updateAtributesGroup(e, group, type) {
    this.loading = true;
    let data = {} as any;
    if (type == 'vista_web') {
      data = {
        tabla: 'billing_productogrupo',
        id: 'codigo',
        valor_id: group.codigo,
        atributo: 'vista_web',
        valor_atributo: e.target.checked ? 1 : 0
      }
    }
    if (type == 'descripcion') {
      data = {
        tabla: 'billing_productogrupo',
        id: 'codigo',
        valor_id: group.codigo,
        atributo: 'descripcion',
        valor_atributo: e
      }
    }

    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.updateAtributeGroup(url, data).then(async (resupd: any) => {
        this.loading = false;
        if (!resupd.error) {
          if (resupd.rta == true) {
            this.toaster.success('Información actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          } else {
            this.toaster.error('Error al actualizar, intente nuevamente', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        } else {
          this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
  }

  async searchGroupName(search) {
    for (let g of this.groupsFree) {
      if ((g.nombre.toUpperCase()).includes(search.toUpperCase())) {
        g.ocult = false;
      } else {
        g.ocult = true;
      }
    }
  }

  async modalConfigurationSubGroup(configurationSubgrupoModal, group) {
    this.groupSelected = {} as any;
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getSubgruposService(url, group.idgrupo).then(async (ressub: any) => {
        group.subgroups = ressub.data;
      });
    });
    this.loading = false;
    this.groupSelected = group;
    if (this.groupSelected.subgroups.viewSubgrupo == true) {
      this.modalCtrl.open(configurationSubgrupoModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then(async (result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      this.toaster.warning('El Grupo seleccionado, No cuenta con subgrupos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  // id_sub = 0 Traer todos los productos sin importar el subgrupo
  async getProductsGrupoSubgrupo(id_sub) {
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      if (id_sub >= 0) {
        await this.restablishFilters('grupo');
        await this.webService.getProductosService(url, this.catalogue.id_grupo, id_sub, this.configuracion).then(async (resprod: any) => {
          if (resprod.rta == true) {
            let aux = this.stablishingPriceProduct(resprod.data);
            aux.push(this.webService.createProductViewGroupSubgroup('-1', this.catalogue.id_grupo, this.groups, id_sub, this.catalogue.subGrupos));
            this.catalogue.products = this.webService.orderProductForId(aux, 'asc');
          } else {
            this.catalogue.products = [];
            this.toaster.warning('No se ha encontrado productos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      } else {
        this.catalogue.name_subgrupo = '';
        await this.restablishFilters('subgrupo');
        await this.webService.getProductosGrupo(url, this.catalogue.id_grupo, this.configuracion).then(async (resprod: any) => {
          if (!resprod.error) {
            if (resprod.rta == true) {
              this.catalogue.products = await this.stablishingPriceProduct(resprod.data);
              await this.webService.clasifyProductsGroupSubgroup('subgroup', this.catalogue, this.groups).then((resprod: []) => {
                this.catalogue.products = resprod;
              });
              // let aux = this.stablishingPriceProduct(resprod.data);
              // let aux_prod = [];
              // aux = await this.webService.orderProductsBySubgroups('', aux);
              // let cont = -1;
              // for (let sg of this.catalogue.subGrupos) {
              //   let arr_aux = [];
              //   // Agrupar productos por grupos
              //   for (let p of aux) {
              //     if (sg.id_sub == p.id_subgrupo) {
              //       arr_aux.push(p);
              //     }
              //   }
              //   // Asignar el producto que cotiene el grupo y subgrupo
              //   if (arr_aux.length > 0) {
              //     arr_aux.push(await this.webService.createProductViewGroupSubgroup(cont, this.catalogue.id_grupo, this.groups, sg.id_sub, this.catalogue.subGrupos));
              //     arr_aux = await this.webService.orderProductForId(arr_aux, 'asc');
              //     for (let a of arr_aux) {
              //       aux_prod.push(a);
              //     }
              //     cont = (cont) + (-1);
              //   }
              // }
              // this.catalogue.products = aux_prod;
            } else {
              this.catalogue.products = [];
              this.toaster.warning('No se ha encontrado resultados', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          }
        });
      }
    });
    this.catalogue.view_products = this.webService.showProducts(this.catalogue.products);
    this.catalogue.products_all = this.catalogue.products;

    // console.log(this.catalogue.products);
    // console.log("Show", this.catalogue.view_products);

    this.loading = false;
  }

  async getProductsAll() {
    this.loading = true;
    await this.restablishFilters('');
    this.catalogue.complete_inventory = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getProductosAll(url, this.configuracion).then(async (resprod: any) => {
        if (resprod.rta == true) {
          this.catalogue.products = await this.stablishingPriceProduct(resprod.data);
          await this.webService.clasifyProductsGroupSubgroup('all', this.catalogue, this.groups).then((resprod: []) => {
            this.catalogue.products = resprod;
          });
        } else {
          this.catalogue.products = [];
          this.toaster.warning('No se ha encontrado resultados', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.catalogue.view_products = this.webService.showProducts(this.catalogue.products);
    this.catalogue.products_all = this.catalogue.products;


    // console.log(this.catalogue.products);
    // console.log("Show", this.catalogue.view_products);


    this.loading = false;
  }

  async updateImageGroup(res, type, other) {
    let data = JSON.parse(res);
    let urlImgAnt = other.img;
    let query = {
      "tabla": "billing_productogrupo",
      "id": "codigo",
      "valor_id": other.codigo,
      "atributo": "img",
      "valor_atributo": data.id
    }
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.updateAttributeTable(url, query).then(async (resupd: any) => {
        if (resupd.rta == true) {
          await this.getGroupFree();
          this.toaster.success('Imagen del grupo actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          if (urlImgAnt) {
            this.appContext.Repository.File.delete(urlImgAnt).then(async (resdrive) => { });
          }
        } else {
          this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
  }

  async updateImageSubgroup(res, type, other) {
    let data = JSON.parse(res);
    let urlImgAnt = other.img;
    let query = {
      "tabla": "subgrupo",
      "id": "id_sub",
      "valor_id": other.id_sub,
      "atributo": "img",
      "valor_atributo": data.id
    }
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.updateAttributeTable(url, query).then(async (resupd: any) => {
        if (resupd.rta == true) {
          other.img = data.id;
          this.toaster.success('Imagen del Subgrupo actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          if (urlImgAnt) {
            this.appContext.Repository.File.delete(urlImgAnt).then(async (resdrive) => { });
          }
        } else {
          this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
  }

  async selectGroup(id_grupo) {
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getProductsByGroupFree(url, id_grupo, this.configuracion).then((resprod: any) => {
        if (resprod.rta == true) {
          for (let p of resprod.data) {
            p.checked_mas_vendido = this.webService.checkedAtributes(p.mas_vendido);
            p.checked_es_promo = this.webService.checkedAtributes(p.es_promo);
            p.checked_vista_web = this.webService.checkedAtributes(p.vista_web);
            p.pro_cod = p.id_producto;
          }
          this.products = resprod.data;
        } else {
          this.products = [];
          this.toaster.warning('No se ha encontrado productos en el grupo seleccionado', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.loading = false;
  }

  async searchProduct(search) {
    this.loading = true;
    let conf = this.configuracion;
    await this.webService.getUrlEmpresa().then(async (url) => {
      conf.id_bodega = 0;
      await this.webService.search_products_free(url, search).then((resprod: any) => {
        if (!resprod.error) {
          if (resprod.rta == true) {
            for (let p of resprod.data) {
              p.checked_mas_vendido = this.webService.checkedAtributes(p.mas_vendido);
              p.checked_es_promo = this.webService.checkedAtributes(p.es_promo);
              p.checked_vista_web = this.webService.checkedAtributes(p.vista_web);
            }
            this.products = resprod.data;
            // console.log(this.products);
          } else {
            this.toaster.warning('No se ha encontrado productos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        } else {
          this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.loading = false;
  }

  async updateImageProduct(res, type, atributo, other) {

    let data = JSON.parse(res);
    let imageDelet;

    if (atributo == 'imagen_uno') {
      imageDelet = other.imagen_uno;
      other.imagen_uno = data.id;
    }
    if (atributo == 'imagen_dos') {
      imageDelet = other.imagen_dos;
      other.imagen_dos = data.id;
    }
    if (atributo == 'imagen_tres') {
      imageDelet = other.imagen_tres;
      other.imagen_tres = data.id;
    }
    if (atributo == 'imagen_cuatro') {
      imageDelet = other.imagen_cuatro;
      other.imagen_cuatro = data.id;
    }

    let query = {
      tabla: 'billing_producto',
      id: 'codigo',
      valor_id: other.pro_cod,
      atributo: atributo,
      valor_atributo: data.id
    }

    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.updateAttributeTable(url, query).then(async (resupd: any) => {
        if (resupd.rta == true) {
          this.toaster.success('Imagen del producto actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          if (imageDelet) {
            this.appContext.Repository.File.delete(imageDelet).then(async (resDrive) => { });
          }
        } else {
          this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
  }

  async getPromotionsWeb() {
    this.loading = true;
    await this.webService.getPromocionesWeb().then(async (resprom: any) => {
      if (resprom.length > 0) {
        for (let p of resprom) {
          if (p.id_producto != 0 && p.id_producto) {
            // console.log("Siii se va", p.id_producto);
            await this.webService.getUrlEmpresa().then(async (url) => {
              await this.webService.getProductosCodigoService(url, p.id_producto, this.configuracion).then(async (resprod: any) => {
                if (resprod.rta == true) {
                  p.nombre_producto = p.id_producto + ' / ' + resprod.data[0].nombre_producto;
                } else {
                  p.nombre_producto = p.id_producto + ' / No encontrado';
                }
              });
            });
          } else {
            // console.log("Nooo se va", p.id_producto);
            p.nombre_producto = 'No asignado';
          }
        }
      }
      this.promotions = resprom;
    });
    this.loading = false;
  }

  async updateTitlePromotion(tituloPromocion) {
    this.configuracion.tituloPromocion = tituloPromocion;
    this.loading = true;
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      this.loading = false;
      await this.getConfiguration();
      this.toaster.success('Titulo sección de promociones actualizada exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
  }

  async modalcreatePromotion(createPromotionModal) {
    this.newPromotion = {
      id_producto: 0,
    };
    this.modalCtrl.open(createPromotionModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      // console.log("imagen", result);
      const file: File = result.files[0];
      if (file && this.newPromotion.nombrePromocion) {
        await this.uploadFileDrive(result, 'Promotion', this.newPromotion);
      } else {
        this.toaster.error('Campos obligatorios vacios, verifique e intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async selectProduct(id_producto) {
    this.newPromotion.id_producto = id_producto;
  }

  async insertPromotionWeb(res, other) {
    let data = JSON.parse(res);
    let query = {
      "nombrePromocion": other.nombrePromocion,
      "descripcion": other.descripcion,
      "id_producto": other.id_producto,
      "nombreImagen": data.name,
      "imgPromocion": data.id
    }
    await this.webService.insertPromotionWeb(query).then(async (respromo: any) => {
      if (!respromo.error) {
        await this.getPromotionsWeb();
        this.toaster.success('Promoción creada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      } else {
        this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async updateImageFooter(res) {
    let data = JSON.parse(res);
    let urlImgAnt = this.configuracion.imgFooter;
    this.configuracion.imgFooter = data.id;
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      await this.getConfiguration();
      this.toaster.success('La Imagen ha sido actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      if (urlImgAnt) {
        this.appContext.Repository.File.delete(urlImgAnt).then(async (resdrive) => { });
      }
    });
  }

  async deletePromotionWeb(promotion) {
    await this.webService.deletePromotionWeb(promotion.id_promocion).then(async (resdel: any) => {
      if (!resdel.error) {
        await this.getPromotionsWeb();
        this.toaster.success('Promoción eliminada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        this.appContext.Repository.File.delete(promotion.imgPromocion).then(async (resDrive) => { });
      } else {
        this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async getDataAdministration() {
    this.loading = true;
    await this.webService.getDataAdministration().then((resadmin) => {
      this.administration = {
        data: resadmin[0],
        validate: {
          current: '',
          new: ''
        }
      }
    });
    // console.log("administration", this.administration);
    this.loading = false;
  }

  async updateAdministration(administration) {
    if (administration.validate.current && administration.validate.new) {
      if (administration.data.password == administration.validate.current) {
        administration.data.password = administration.validate.new;
        this.loading = true;
        await this.webService.updateAdministration(administration.data).then(async (resupd: any) => {
          this.loading = false;
          if (!resupd.error) {
            await this.getDataAdministration();
            this.toaster.success('Contraseña actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          } else {
            this.toaster.error('Ha ocorrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      } else {
        this.toaster.error('Las constraseñas no coinciden, vueva a intentarlo', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    } else {
      this.toaster.error('Campos vacios, verifique e intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async listActualCotizations() {
    await this.restoreQueryGetCotizations().then(async (resrestore) => {
      this.queryCortizations = resrestore;
      await this.getDateCurrent();
      await this.getCotizations(this.queryCortizations, 'No se ha encontrado resultados de la fecha actual');
    });
  }

  async listAllCotizations() {
    await this.restoreQueryGetCotizations().then(async (resrestore) => {
      this.queryCortizations = resrestore;
      await this.getCotizations(this.queryCortizations, 'No se ha encontrado resultados');
      await this.getDateCurrent();
    });
  }

  async listRangeDateCotizations() {
    this.queryCortizations.cedula = '';
    await this.getCotizations(this.queryCortizations, 'No se ha encontrado resultados');
  }

  async listCotizationsById() {
    if (this.queryCortizations.cedula) {
      this.queryCortizations.desde = '';
      this.queryCortizations.hasta = '';
      await this.getCotizations(this.queryCortizations, 'No se ha encontrado resultados');
      await this.getDateCurrent();
    } else {
      this.toaster.warning('Campo vacío, Ingrese el número de identificación', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async getCotizations(data, message) {
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getCotizationsClient(url, data).then((rescoti: any) => {
        if (rescoti.rta == true) {
          this.cotizations = this.webService.orderCotizationsById(rescoti.data, 'desc');
        } else {
          this.toaster.warning(message, '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          this.cotizations = [];
        }
      });
    });
    this.loading = false;
  }

  async restoreQueryGetCotizations() {
    this.queryCortizations = {
      cedula: '',
      desde: '',
      hasta: ''
    }
    return this.queryCortizations;
  }

  async getDateCurrent() {
    await this.webService.getDate(0).then(async (resdate: any) => {
      this.queryCortizations.desde = resdate.año + '-' + resdate.mes_num + '-' + resdate.dia;
      this.queryCortizations.hasta = resdate.año + '-' + resdate.mes_num + '-' + resdate.dia;
    });
  }

  async modalShowDetailCotization(showDetailCotizationModal, cotization) {
    this.cotizationsSelected = cotization;
    this.modalCtrl.open(showDetailCotizationModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.cotizationsSelected = {};
    });
  }

  async updatePixelfacebook(p) {
    if (p) {
      this.loading = true;
      this.configuracion.pixel_facebook = p;
      await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
        await this.webService.createBodyMailPixelFacebook(this.configuracion).then(async (resbody) => {
          await this.webService.sendMailService(resbody).then((resmail) => { });
        });
        this.loading = false;
        await this.getConfiguration();
        this.toaster.success('Administración de Pixel Facebook solicitada.', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      });
    } else {
      this.toaster.error('Campo vacio, Ingrese el código para configurar Pixel de Facebook.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async selectTypePrice(price) {
    this.loading = true;
    this.catalogue.type = price;
    this.catalogue.products = this.stablishingPriceProduct(this.catalogue.products);
    this.loading = false;
  }

  stablishingPriceProduct(product) {
    for (let p of product) {
      if (p.id_producto > 0) {
        p.precioReal = parseFloat(p.precios[0].valor_mas_iva).toFixed(2);
        p.marca_nombre = this.addNameMarKProduct(p.marca_id);
        for (let pric of p.precios) {
          if (pric.id_tipo == this.catalogue.type) {
            p.precioReal = parseFloat(pric.valor_mas_iva).toFixed(2);
          }
        }
      }
    }
    return product;
  }

  addNameMarKProduct(id_marca) {
    let name;
    for (let m of this.catalogue.marcas) {
      if (id_marca == m.id) {
        name = m.nombre;
      }
    }
    return name;
  }

  async generateCataloguePdf() {
    this.toaster.warning('Funcionalidad en proceso ...', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  }

  getNameGrupoSubgrupo(id, type) {
    let name;
    this.catalogue.complete_inventory = false;

    if (type == 'grupo') {
      for (let g of this.groups) {
        if (g.idgrupo == id) {
          name = this.webService.convertStringTypeSentence(g.nombre);
        }
      }
    }

    if (type == 'subgrupo') {
      // console.log(this.catalogue.subGrupos);
      for (let s of this.catalogue.subGrupos) {
        if (s.id_sub == id) {
          name = this.webService.convertStringTypeSentence(s.nombre);
        }
      }
    }

    return name;
  }

  viewAttributes(e) {
    let type = e.target.value;

    if (type == 'stock') {
      this.catalogue.stock = e.target.checked;
    }

    if (type == 'description') {
      this.catalogue.description = e.target.checked;
    }

    if (type == 'marca') {
      this.catalogue.marca = e.target.checked;
    }

    if (type == 'peso') {
      this.catalogue.peso = e.target.checked;
    }

    if (type == 'medida') {
      this.catalogue.medida = e.target.checked;
    }

    if (type == 'ubicacion') {
      this.catalogue.ubicacion = e.target.checked;
    }

    if (type == 'origen') {
      this.catalogue.origen = e.target.checked;
    }

    if (type == 'cant_bulto') {
      this.catalogue.cant_bulto = e.target.checked;
    }

  }

  async searchProductsCatalogue(search) {
    this.loading = true;
    await this.restablishFilters('search');
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.searchProduct(url, search, this.configuracion).then(async (ressearch: any) => {
        if (ressearch.rta == true) {
          this.catalogue.products = await this.stablishingPriceProduct(ressearch.data);
          await this.webService.clasifyProductsGroupSubgroup('search', this.catalogue, this.groups).then((resprod: []) => {
            this.catalogue.products = resprod;
          });
        } else {
          this.toaster.error('No se ha encontrado resultados en la busqueda', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.catalogue.view_products = this.webService.showProducts(this.catalogue.products);
    this.catalogue.products_all = this.catalogue.products;
    // console.log(this.catalogue.products);
    // console.log("Show", this.catalogue.view_products);
    this.loading = false;
  }

  async filterProductForOrigin(origin) {
    this.loading = true;
    // Filtrar
    if (origin > 0) {
      this.catalogue.products = [];
      await this.webService.filterForOriginMarca('origen', origin, this.catalogue.products_all, this.catalogue.marca_select).then(async (resfilter) => {
        this.catalogue.products = resfilter;
      });
      await this.restablishFilters('origin_marca');
    } else {
      this.catalogue.products = this.catalogue.products_all;
      await this.restablishFilters('grupo');
    }
    this.catalogue.view_products = await this.webService.showProducts(this.catalogue.products);
    // Mostrar/Ocultar producto qu contiene grupo/subgrupo
    this.catalogue.products = await this.webService.enableDisableProductGroupSubgroup(this.catalogue.products);
    // console.log(this.catalogue.products);
    this.loading = false;
  }

  async filterProductForMarca(e) {
    this.loading = true;
    if (e > 0) {
      this.catalogue.products = [];
      await this.webService.filterForOriginMarca('marca', e, this.catalogue.products_all, this.catalogue.origen_select).then(async (resfilter) => {
        this.catalogue.products = resfilter;
      });
      await this.restablishFilters('origin_marca');
    } else {
      this.catalogue.products = this.catalogue.products_all;
      await this.restablishFilters('grupo');
    }
    this.catalogue.view_products = await this.webService.showProducts(this.catalogue.products);
    // Mostrar/Ocultar producto qu contiene grupo/subgrupo
    this.catalogue.products = await this.webService.enableDisableProductGroupSubgroup(this.catalogue.products);

    // console.log(this.catalogue.products);

    this.loading = false;
  }

  savePdf() {
    const DATA = document.getElementById('section-pdf');
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_tutorial.pdf`);

    });

  }

  async updateAtributesProduct(e, type, product) {
    this.loading = true;

    let data = {
      tabla: 'billing_producto',
      id: 'codigo',
      valor_id: '',
      atributo: '',
      valor_atributo: 0
    }
    let val = 0;
    let send = {}

    if (type == 'mas_vendido' || type == 'es_promo' || type == 'vista_web') {
      if (e.target.checked == true) {
        val = 1;
      } else {
        val = 0;
      }
    }

    if (type == 'mas_vendido') {
      data.valor_id = product.pro_cod;
      data.atributo = 'mas_vendido';
      data.valor_atributo = val;
    }

    if (type == 'es_promo') {
      data.valor_id = product.pro_cod;
      data.atributo = 'es_promo';
      data.valor_atributo = val;
    }

    if (type == 'vista_web') {
      data.valor_id = product.pro_cod;
      data.atributo = 'vista_web';
      data.valor_atributo = val;
    }

    await this.webService.getUrlEmpresa().then(async (url) => {
      if (type == 'descripcion') {
        let bandera = false;
        // Actualizar descripcion o video de yotube
        if (product.descripcion != this.variables.descripcion || product.fotourl != this.variables.fotourl) {
          bandera = true;
          send = {
            data: {
              "tabla": "billing_producto",
              "id": "codigo",
              "valor_id": product.id_producto,
              "datos": {
                "descripcion": product.descripcion,
                "fotourl": product.fotourl
              }
            },
            "endPoint": url + "update_atributo_tabla"
          }
          await this.webService.updateAttributesTableBilling(send).then(async (res: any) => {
            this.loading = false;
            if (!res.error) {
              if (res.rta == true) {
                this.toaster.success('Información actualizada con éxito', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              } else {
                this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            } else {
              this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        }
        // Validar si viene referido para agregarlo
        // if (e) {
        //   bandera = true;
        //   if (this.webService.validateNumbers(e)) {
        //     let data = {
        //       id_producto: product.id_producto,
        //       id_referido: e,
        //       bodega_id: this.configuracion.id_bodega
        //     }
        //     await this.webService.insertReferidosProduct(url, data).then(async (resref: any) => {
        //       if (resref.rta == true) {
        //         await this.webService.getInfoProductsReferidos(product, 'configuracion', this.configuracion).then(async (resreferidos: any) => {
        //           this.productReferidos = this.formatNameProductReferido(resreferidos);
        //         });
        //         this.toaster.success(resref.message, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        //       } else {
        //         this.toaster.error(resref.message, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        //       }
        //     });
        //   } else {
        //     this.toaster.error('El código de refereido debe contener solo números', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        //   }
        // }
        if (bandera == false) {
          this.toaster.warning('No se realizó ningún cambio, campos vacios o similares', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
        this.loading = false;
      } else {
        await this.webService.updateAtributeProduct(url, data).then((resupd: any) => {
          this.loading = false;
          if (!resupd.error) {
            if (resupd.rta == true) {
              this.toaster.success('Información actualizada con éxito', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            } else {
              this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          } else {
            this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      }
    });
  }

  async getAtributesProducts() {
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getAttributesProducts(url).then(async (resatr: any) => {
        if (resatr.rta == true) {
          let aux = {
            id: 0,
            nombre: ' * TODAS',
            descripcion: 'Default'
          }
          resatr.marca.push(aux);
          await this.webService.orderObjectsAsc(resatr.marca).then((res) => {
            this.catalogue.marcas = res;
          })
        }
      });
    });
    this.loading = false;
  }

  async restablishFilters(type) {

    if (type == '') {
      this.catalogue.subGrupos = [];
      // this.catalogue.verAll = false;
      this.catalogue.search = '';
      this.catalogue.marca_select = 0;
      this.catalogue.origen_select = 0;
    }

    if (type == 'search') {
      this.catalogue.subGrupos = [];
      this.catalogue.marca_select = 0;
      this.catalogue.origen_select = 0;
    }

    if (type == 'grupo') {
      this.catalogue.search = '';
      this.catalogue.marca_select = 0;
      this.catalogue.origen_select = 0;
    }

    if (type == 'subgrupo') {
      this.catalogue.search = '';
      this.catalogue.marca_select = 0;
      this.catalogue.origen_select = 0;
    }

    if (type == 'origin_marca') {
      // this.catalogue.verAll = false;
      this.catalogue.search = '';
    }

  }

  async viewAttribuesProduct(value, type) {
    this.loading = true;
    switch (type) {
      case 'descripcion':
        this.configuracion.descripcion_producto = value.target.checked;
        break;
      case 'marca':
        this.configuracion.marca_producto = value.target.checked;
        break;
      case 'peso':
        this.configuracion.peso_producto = value.target.checked;
        break;
      case 'medida':
        this.configuracion.medida_producto = value.target.checked;
        break;
      case 'ubicacion':
        this.configuracion.ubicacion_producto = value.target.checked;
        break;
      case 'origen':
        this.configuracion.origen_producto = value.target.checked;
        break;
      case 'cantidadxbulto':
        this.configuracion.cantidadxbulto_producto = value.target.checked;
        break;
      default:
    }
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      this.loading = false;
      await this.getConfiguration();
      this.toaster.success('Atributo a visualizar actualizado exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
  }

  async getColorsTable() {
    this.loading = true;
    await this.webService.getColors().then(async (rescolors: any) => {
      if (!rescolors.error) {
        if (rescolors.rta == true) {
          this.colorsTable = rescolors.data;
        }
      } else {
        this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
    this.loading = false;

  }

  async addColorTable(newColor) {
    if (newColor.nombre && newColor.codigo) {
      this.loading = true;
      newColor.nombre = newColor.nombre.toUpperCase();
      await this.webService.addColorTable(newColor).then(async (rescolor: any) => {
        if (!rescolor.error) {
          if (rescolor.rta == true) {
            await this.getColorsTable();
            this.toaster.success('Color registrado exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          } else {
            this.toaster.warning(rescolor.message, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        } else {
          this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
      this.loading = false;
    } else {
      this.toaster.error('Campos vacios, agrege la información', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async deleteColorTable(color) {
    await this.webService.deleteColorTable(color).then(async (resdel: any) => {
      if (!resdel.error) {
        if (resdel.rta == true) {
          await this.getColorsTable();
          this.toaster.success('Color eliminado exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.warning('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      } else {
        this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async modalConfigurationAtributesProduct(configurationProductModal, product) {
    // Agregar datos para controlar si cambiaron proceder a su actualizacion
    this.productReferidos = [];
    this.variables.descripcion = product.descripcion;
    this.variables.fotourl = product.fotourl;
    this.searchProductsReferidos = {};
    this.searchProd = '';
    // Obtener los referidos
    if (product.referidos) {
      this.loading = true;
      await this.webService.getInfoProductsReferidos(product, 'configuracion', this.configuracion).then(async (resreferidos: any) => {
        this.productReferidos = this.formatNameProductReferido(resreferidos);
      });
      this.loading = false;
    }
    this.productSelected = product;
    this.modalCtrl.open(configurationProductModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      this.variables = {
        descripcion: '',
        fotourl: '',
        referido: '',
      }

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.cotizationsSelected = {};
      this.variables = {
        descripcion: '',
        fotourl: '',
        referido: ''
      }
    });
  }

  formatNameProductReferido(referidos) {
    for (let p of referidos) {
      let name_code = p.codigo + ' / ' + p.nombreUnico;
      p.nombre = this.webService.setDescriptionTitle(name_code, 'title-referidos');
    }
    return referidos;
  }

  async deleteProductReferido(product, productReferidos, productSelected) {
    this.loading = true;
    let new_referido = '';
    // Eliminar el producto referido del objeto
    for (let p of productReferidos) {
      if (p.codigo == product.codigo) {
        productReferidos.splice(productReferidos.indexOf(p), 1);
      }
    }
    // Crear los referidos
    for (let p of productReferidos) {
      new_referido += p.codigo + ',';
    }
    // Actualizar nuevos referidos
    let data = {
      tabla: 'billing_producto',
      id: 'codigo',
      valor_id: productSelected.id_producto,
      atributo: 'referidos',
      valor_atributo: new_referido.slice(0, -1)
    }
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.updateAtributeProduct(url, data).then((resupd: any) => { });
    });
    this.loading = false;
  }

  async searchProductForReferido(searchProd) {
    if (searchProd.length > 0) {
      this.loading = true;
      await this.webService.getUrlEmpresa().then(async (url) => {
        await this.webService.searchProductCodeName(url, searchProd, this.configuracion).then((ressearch: any) => {
          if (!ressearch.error) {
            if (ressearch.rta == true) {
              this.searchProductsReferidos = ressearch.data
            } else {
              this.toaster.warning('No se ha encontrado resultados', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          } else {
            this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      });
      this.loading = false;
    } else {
      this.toaster.error('Ingrese el producto que desea buscar', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async addProductReferido(product, productSelected) {
    let data = {
      id_producto: productSelected.id_producto,
      id_referido: product.id_producto,
      bodega_id: this.configuracion.id_bodega
    }
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.insertReferidosProduct(url, data).then(async (resref: any) => {
        if (resref.rta == true) {
          await this.webService.getInfoProductsReferidos(productSelected, 'configuracion', this.configuracion).then(async (resreferidos: any) => {
            this.productReferidos = this.formatNameProductReferido(resreferidos);
          });
          this.toaster.success(resref.message, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.error(resref.message, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.loading = false;
  }

  async getProvinces() {
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getProvincesEcuador(url).then(async (resprovinces: any) => {
        if (!resprovinces.error) {
          if (resprovinces.rta == true) {
            this.provinces = resprovinces.data;
          } else {
            this.toaster.warning('No se ha encontrado provincias', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        } else {
          this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.loading = false;
  }

  async updateCostShippingProvince(province) {
    let aux = false;
    // Comprobar si el valor es valido
    if (!province.precio_envio || province.precio_envio < 0) {
      aux = false;
    } else {
      if (province.precio_envio == 0) {
        aux = true;
      } else {
        aux = this.webService.validateNumbers(province.precio_envio);
      }
    }
    // Si el valor es valido se actualiza
    if (aux == true) {
      this.loading = true;
      let data = {
        tabla: 'bill_provincia',
        id: 'idProvincia',
        valor_id: province.idProvincia,
        atributo: 'precio_envio',
        valor_atributo: province.precio_envio
      }
      await this.webService.getUrlEmpresa().then(async (url) => {
        this.loading = false;
        await this.webService.updateAtributeProvince(url, data).then((resupd: any) => {
          if (resupd.rta == true) {
            this.getProvinces();
            this.toaster.success('El costo ha sido actualizado', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          } else {
            this.toaster.warning('Ha ocurrido un error, Intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      });
    } else {
      this.toaster.error('Valor no válido, verifique e intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async getOpcionMenu() {
    await this.webService.getMenu().then(async (resmenu: any) => {
      await this.webService.stablishOptionsMenu(resmenu).then((resm: any) => {
        this.menu_client = resm.menu;
      });
    });
  }

  async updateMenu(menu) {
    menu.nombre = menu.nombre.toUpperCase();
    this.loading = true;
    let upd = {
      descripcion: menu.descripcion,
      id_empresa: menu.id_empresa,
      id_menu: menu.id_menu,
      nombre: menu.nombre.toUpperCase(),
      referencia: menu.referencia,
    }
    await this.webService.updateMenu(upd).then(async (resupd: any) => {
      if (resupd.rta == true) {
        this.toaster.success('Nombre del menú ha sido actualizado', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      } else {
        this.toaster.error('Algo ha sucedido, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
    this.loading = false;
  }


  // Inicio Guia de tallas

  async getGuiaTallasAll() {
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getGuiaTallasAll(url).then(async (resguia: any) => {
        console.log("resguia", resguia);
        if (resguia.rta == true) {
          this.guiaTallasAll = resguia.data;
          await this.viewGuiaTalla(this.guiaTallasAll[0], this.guiaTallasAll);
        } else {
          this.guiaTallasAll = [] as any;
          this.toaster.warning('No se ha encontrado resultados', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.loading = false;
  }

  async modalCreateGuiaTallas(createGuiaTallasModal) {
    await this.inizializeNewGuiaTallas('all');
    await this.getGroupFree();
    this.guiaTallas = {} as any;
    this.modalCtrl.open(createGuiaTallasModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      console.log("vieneeee", result);
    }, (reason) => {
      this.groupsFree = [];
      this.getGuiaTallasAll();
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async inizializeNewGuiaTallas(type) {
    if (type == 'all') {
      this.newGuiaTalla = {
        guiaTallas: {
          id_grupo: null,
          medida: '',
          descripcion: ''
        },
        guia_tallas_detalle: {
          id_guia: null,
          nombre: '',
          detalle: ''
        }
      };
    }
    if (type == 'detail') {
      this.newGuiaTalla.guia_tallas_detalle.id_guia = null;
      this.newGuiaTalla.guia_tallas_detalle.nombre = '';
      this.newGuiaTalla.guia_tallas_detalle.detalle = '';
    }
  }

  async selectGroupGuiaTalla(id_grupo) {
    this.loading = true;
    this.guiaTallas = {} as any;
    this.newGuiaTalla.guiaTallas.id_grupo = id_grupo;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getGuiaTallasGroupId(url, id_grupo).then(async (resguia: any) => {
        console.log("resguia", resguia);
        this.guiaTallas = resguia;
      });
    });
    this.loading = false;
  }

  async createGuiaTallas(guiaTallas) {
    if (guiaTallas.id_grupo && guiaTallas.medida) {
      this.loading = true;
      await this.webService.getUrlEmpresa().then(async (url) => {
        let send = {
          data: guiaTallas,
          "endPoint": url + "register_guia_talla"
        }
        await this.webService.insertTableBillingPost(send).then(async (rescreate: any) => {
          if (!rescreate.error) {
            if (rescreate.rta == true) {
              await this.selectGroupGuiaTalla(guiaTallas.id_grupo);
              this.toaster.success('Guía de talla registrada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            } else {
              this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          } else {
            this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      });
      this.loading = false;
    } else {
      this.toaster.error('Campos vacios, Ingrese la información solicitada', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async updateGuiaTallas(guiaTallas) {
    if (guiaTallas.medida && guiaTallas.descripcion) {
      this.loading = true;
      let upd = {
        id_guia: guiaTallas.id_guia,
        id_grupo: guiaTallas.id_grupo,
        medida: guiaTallas.medida,
        descripcion: guiaTallas.descripcion
      }
      await this.webService.getUrlEmpresa().then(async (url) => {
        let send = {
          data: upd,
          "endPoint": url + "update_guias_talla"
        }
        await this.webService.insertTableBillingPost(send).then(async (rescreate: any) => {
          if (!rescreate.error) {
            if (rescreate.rta == true) {
              this.toaster.success('La guía de talla ha sido actualizado exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            } else {
              this.toaster.warning('No existen cambios para actualizarlos', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          } else {
            this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      });
      this.loading = false;
    } else {
      this.toaster.error('Campos vacios, Ingrese la información solicitada', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async deleteGuiaTallas(guiaTallas) {
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.deleteGuiaTalla(url, guiaTallas.id_guia).then(async (resdelete: any) => {
        // console.log("resdelete", resdelete);
        if (resdelete.rta == true) {
          await this.selectGroupGuiaTalla(guiaTallas.id_grupo);
          this.toaster.success('Talla eliminada exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.error('Ha ocurrido un erros, Intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.loading = false;
  }

  async modalCreateGuiaTallasDetalle(createGuiaTallasDetalleModal, guiaTalla) {
    await this.inizializeNewGuiaTallas('detail');
    this.name_guia = guiaTalla.medida;
    this.guiaTallasDetalle = guiaTalla;
    this.newGuiaTalla.guia_tallas_detalle.id_guia = guiaTalla.id_guia;
    this.modalCtrl.open(createGuiaTallasDetalleModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async createGuiaTallasDetalle(guiaTallasDetalle) {
    this.loading = true;
    if (guiaTallasDetalle.nombre && guiaTallasDetalle.detalle) {
      await this.webService.getUrlEmpresa().then(async (url) => {
        let send = {
          data: guiaTallasDetalle,
          "endPoint": url + "register_guia_talla_detalle"
        }
        await this.webService.insertTableBillingPost(send).then(async (rescreate: any) => {
          if (!rescreate.error) {
            if (rescreate.rta == true) {
              await this.selectGroupGuiaTalla(this.guiaTallasDetalle.id_grupo);
              // Mostrar en la lista el nuevo detalle
              this.guiaTallasDetalle = this.getDetailTalla(this.guiaTallas.data, this.guiaTallasDetalle, 'create');
              this.toaster.success('Talla registrada exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            } else {
              this.toaster.warning('La medida ya se encuentra registrada', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          } else {
            this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      });
    } else {
      this.toaster.error('Campos vacios, Ingrese la información solicitada', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
    this.loading = false;
  }

  getDetailTalla(guiaTallas, guiaTallasDetalle, type) {
    let aux;
    if (type == 'create') {
      for (let t of guiaTallas) {
        if (t.id_guia == guiaTallasDetalle.id_guia) {
          aux = t;
        }
      }
    }
    if (type == 'delete') {
      for (var i = 0; i < guiaTallas.detalle.length; i++) {
        if (guiaTallas.detalle[i].id_guia_detalle == guiaTallasDetalle.id_guia_detalle) {
          guiaTallas.detalle.splice(i, 1);
        }
      }
      aux = guiaTallas;
    }
    return aux;
  }

  async deleteGuiaTallasDetalle(guia_detalle) {
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.deleteGuiaTallaDetalle(url, guia_detalle.id_guia_detalle).then(async (resdelete: any) => {
        // console.log("resdelete", resdelete);
        if (resdelete.rta == true) {
          this.guiaTallasDetalle = this.getDetailTalla(this.guiaTallasDetalle, guia_detalle, 'delete');
          this.toaster.success('Talla eliminada exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.error('Ha ocurrido un erros, Intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.loading = false;
  }

  async updateGuiaTallasDetalle(guia_detalle) {
    if (guia_detalle.nombre && guia_detalle.detalle) {
      this.loading = true;
      await this.webService.getUrlEmpresa().then(async (url) => {
        let send = {
          data: guia_detalle,
          "endPoint": url + "update_guias_talla_detalle"
        }
        await this.webService.insertTableBillingPost(send).then(async (rescreate: any) => {
          if (!rescreate.error) {
            if (rescreate.rta == true) {
              this.toaster.success('El detalle sa sido actualizado exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            } else {
              this.toaster.warning('No existen cambios para actualizarlos', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          } else {
            this.toaster.error('Error en el servidor, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      });
      this.loading = false;
    } else {
      this.toaster.error('Campos vacios, Ingrese la información solicitada', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async viewGuiaTalla(guiaTallas, guiaTallasAll) {
    // console.log("Camellito", guiaTallas);
    if (guiaTallas.view == false) {
      for (let g of guiaTallasAll) {
        if (g.id_grupo == guiaTallas.id_grupo) {
          g.view = !guiaTallas.view;
        } else {
          g.view = false;
        }
      }
      this.guiaTallas = guiaTallas.guias;
    } else {
      for (let g of guiaTallasAll) {
        g.view = false;
      }
      this.guiaTallas = {} as any;
    }
  }
  // Finnnn Guia de tallas

  async generateVaucher() {
    this.myAngularxQrCode = '';
    if (this.vaucher.valor_transaccion > 0 && this.vaucher.detalle_transaccion) {
      this.loading = true;
      await this.webService.getCurrentDate().then((resdate: any) => {
        this.vaucher.fecha_creacion = resdate.year + '-' + resdate.month_number + '-' + resdate.day_number;
        this.vaucher.hora_creacion = resdate.hour + ':' + resdate.minute + ':' + resdate.second;
      });
      this.vaucher.id_empresa = this.configuracion.id_empresa;
      await this.webService.generateVaucher(this.vaucher).then((resgene: any) => {
        if (resgene.rta) {
          // this.myAngularxQrCode = this.configuracion.dominioPagina + '/vaucher/' + resgene.id;
          this.myAngularxQrCode = this.configuracion.dominioPagina + '/vaucher?id=' + resgene.id;
          this.toaster.success('Vaucher generado exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.error('Error al generar el vaucher, Intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
      this.loading = false;
    } else {
      this.toaster.warning('Campos vacios, Ingrese la información solicitada', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async sharedWhatsapp(){
    await this.webService.getInformacion().then((datainfo: any) => {
      window.open("https://wa.me/" + datainfo[0].whatsapp + "?text=" + this.myAngularxQrCode + "", "_blank");
    });
  }

  async openLik(){
    window.open(this.myAngularxQrCode, "_blank");
  }

}
